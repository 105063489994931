export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)

  // console.log(current);
  // console.log(url);

  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.startsWith(url)) {
    return true
  }

  return false
}
