/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  RankingPromoters,
  LatestEvents,
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/*begin::Col*/}
      <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3'>
        {/*begin::Card widget 3*/}
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100 mb-5 mb-xl-10'
          style={{ backgroundColor: '#6609BC' }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                26
              </span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
                Eventos em Andamento
              </span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>26 Em andamento</span>
                <span>28%</span>
              </div>
              <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                <div
                  className='bg-white rounded h-8px'
                  role='progressbar'
                  aria-valuenow={28}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '28%' }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*end::Card widget 3*/}
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3'>
        {/*begin::Card widget 3*/}
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100 mb-5 mb-xl-10'
          style={{
            backgroundColor: '#98C400',
          }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                69
              </span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
                Total de Eventos
              </span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>43 Finalizados</span>
                <span>72%</span>
              </div>
              <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                <div
                  className='bg-white rounded h-8px'
                  role='progressbar'
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '72%' }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*end::Card widget 3*/}
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3'>
        {/*begin::Card widget 3*/}
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100 mb-5 mb-xl-10'
          style={{
            backgroundColor: 'rgb(241, 65, 108)',
            backgroundImage: 'url("/media/patterns/vector-1.png")',
          }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                121
              </span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
                Promoters
              </span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>36 Ativos</span>
                <span>30%</span>
              </div>
              <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                <div
                  className='bg-white rounded h-8px'
                  role='progressbar'
                  aria-valuenow={30}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '30%' }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*end::Card widget 3*/}
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3'>
        {/*begin::Card widget 3*/}
        <div
          className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-100 mb-5 mb-xl-10'
          style={{
            backgroundColor: '#2273FF',
          }}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
                456
              </span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
                Média de Convidados por Evento
              </span>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>43 Pending</span>
                <span>72%</span>
              </div>
              <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                <div
                  className='bg-white rounded h-8px'
                  role='progressbar'
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: '72%' }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*end::Card widget 3*/}
      </div>
      {/*end::Col*/}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <RankingPromoters
          description='Convidados por Promoters'
          subdescription='Ranking'
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xl-4'>
        <RankingPromoters
          description='Média de Convidados'
          subdescription='Ranking'
          className='card-xl-stretch mb-xl-8'
        />
      </div>
      <div className='col-xl-4'>
        <RankingPromoters
          description='Média de Checkins'
          subdescription='Ranking'
          className='card-xl-stretch mb-xl-8'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Col */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-12'>
        {/* Últimos Evento */}
        <LatestEvents className='card-xl-stretch mb-5 mb-xl-8' />
        {/* Últimos Evento */}
      </div>
    </div>
    {/* end::Col */}

    {/* begin::Row */}
    {/* <div className='row gy-5 gx-xl-8'>
            <div className='col-xxl-4'>
                <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
            </div>
            <div className='col-xl-8'>
                <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
            </div>
        </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
            <div className='col-xl-4'>
                <ListsWidget2 className='card-xl-stretch mb-xl-8' />
            </div>
            <div className='col-xl-4'>
                <ListsWidget6 className='card-xl-stretch mb-xl-8' />
            </div>
            <div className='col-xl-4'>
                <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
            </div>
        </div> */}
    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
            <div className='col-xxl-4'>
                <MixedWidget8
                    className='card-xxl-stretch mb-xl-3'
                    chartColor='success'
                    chartHeight='150px'
                />
            </div>
            <div className='col-xxl-8'>
                <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
            </div>
        </div> */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]} description={'Dashboard'}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
