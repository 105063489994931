import { IGetCurrentUrlParamsObj } from '@resources/types/utils/url'

export const getCurrentUrlParams = (
  urlParams: IGetCurrentUrlParamsObj[]
): object => {
  const urlSearch = new URLSearchParams(decodeURI(window.location.search))
  let urlParamsValue: any = {}

  for (let i = 0; i < urlParams.length; i++) {
    const urlParam: IGetCurrentUrlParamsObj = urlParams[i]
    const { name, defaultValue } = urlParam

    urlParamsValue[name] = urlSearch.get(name) || defaultValue
  }

  return urlParamsValue
}

export const formatYoutubeUrl = (url: string) => {
  const formattedUrl = url.replace('watch?v=', 'embed/')
  return formattedUrl
}
