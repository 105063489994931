import { useState, useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { useFormik } from 'formik'

import { useAuthLogin } from 'src/hooks/auth/useAuth'
import {
  IAuthUser,
  IAuthLoginErrorResponse,
} from 'src/resources/types/services/auth'

import { useAuth } from '../../core/Auth'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { loginSchema } from './form/schema'
import { IAcceptStaffInviteUrlParams } from 'src/resources/types/modules/auth'
import { getCurrentUrlParams } from 'src/resources/utils/url'
import { showAcceptStaffInviteSuccessMessage } from 'src/resources/utils/staff'
import { useIntl } from 'react-intl'

const initialValues = {
  email: '',
  password: '',
}

export const useLogin = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useAuthLogin()

  const { saveAuth, setCurrentUser } = useAuth()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const [errorMenssage, setErrorMenssage] = useState('')

  const urlParams: IAcceptStaffInviteUrlParams = getCurrentUrlParams([
    {
      name: 'eventLevelHash',
      defaultValue: undefined,
    },
    {
      name: 'accessLevelId',
      defaultValue: null,
    },
    {
      name: 'eventName',
      defaultValue: '',
    },
  ]) as IAcceptStaffInviteUrlParams

  const { eventLevelHash, accessLevelId, eventName } = urlParams

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()
    setRecaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify().catch((error) => console.error(error))
  }, [handleReCaptchaVerify])

  const onSuccess = async (
    response: IAuthUser | IAuthLoginErrorResponse
  ): Promise<void> => {
    if (!response) {
      setErrorMenssage('Usuário ou senha estão incorretos.')

      formik.resetForm()
      return
    }

    showAcceptStaffInviteSuccessMessage({
      eventLevelHash,
      eventName,
      accessLevelId,
      intl,
    })

    queryClient.setQueryData(['authUser'], response)
    setCurrentUser(response as IAuthUser)
  }

  const onError = (error: any): void => {
    saveAuth(undefined)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      mutate(
        {
          email: values.email,
          password: values.password,
          captcha: recaptchaToken ?? 'Teste12#',
          event_level_hash: eventLevelHash,
        },
        {
          onSuccess,
          onError,
        }
      )
    },
  })

  return {
    formik,
    isLoading,
    errorMenssage,
  }
}
