import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { EventSingleAddGuests } from '../pages/EventSingleAddGuests'
import { EventSingleCheckin } from '../pages/EventSingleCheckin'
import { EventSingleAddStaff } from '../pages/EventSingleAddStaff'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import {
  ADD_GUESTS_ROUTE,
  ADD_STAFF_ROUTE,
  CHECKIN_ROUTE,
} from 'src/constants/routes'

const EventsLandingLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bg-black bgi-position-y-bottom position-x-center bgi-no-repeat bgi-auth'
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/bgs/backstage-noise-v2.jpg'
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10'>
        {/* begin::Wrapper */}
        <div className='w-100 mw-1000px mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}

      {/* begin::Footer}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a href='#' className='text-muted text-hover-primary px-2'>
                        About
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact Us
                    </a>
                </div>
            </div>
            {/* end::Footer */}
    </div>
  )
}

const EventsCheckinLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bg-black bgi-position-y-bottom position-x-center bgi-no-repeat bgi-auth'
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/bgs/backstage-noise-v2.jpg'
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid'>
        {/* begin::Wrapper */}
        <div className='w-100 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}

      {/* begin::Footer}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a href='#' className='text-muted text-hover-primary px-2'>
                        About
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact Us
                    </a>
                </div>
            </div>
            {/* end::Footer */}
    </div>
  )
}

const EventRoutes = () => {
  // const EventsLanding = lazy(() => import('../pages/EventsLanding'))

  return (
    <Routes>
      <Route element={<EventsLandingLayout />}>
        <Route path={ADD_GUESTS_ROUTE} element={<EventSingleAddGuests />} />
        <Route index element={<EventSingleAddGuests />} />
        <Route path={ADD_STAFF_ROUTE} element={<EventSingleAddStaff />} />
      </Route>
      <Route element={<EventsCheckinLayout />}>
        <Route path={CHECKIN_ROUTE} element={<EventSingleCheckin />} />
        <Route index element={<EventSingleCheckin />} />
      </Route>
    </Routes>
  )
}

export { EventRoutes }
