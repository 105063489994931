import { toAbsoluteUrl, KTIcon } from 'src/_metronic/helpers'
import { PageTitle } from 'src/_metronic/layout/core'
import { useLayout } from 'src/_metronic/layout/core'
import { useIntl } from 'react-intl'
import { FC, useEffect } from 'react'
import { useState } from 'react'
import { IEventGuestData } from '@resources/types/services/guests'
import { CheckInStatus } from 'src/resources/types/pages/guests'
import { useEventSingleCheckin } from './useEventSingleCheckin'
import { IEventSingleCheckinPage } from 'src/resources/types/pages/checkin'
import { generateKey } from 'src/resources/utils/generateKey'
import { IEventInfoData } from '@resources/types/services/events'
import { useNavigate } from 'react-router-dom'
import { DAHSBOARD_BY_EVENT_ID_ROUTE, EVENTS_ROUTE } from 'src/constants/routes'
import { AdmLevelId } from 'src/resources/types/pages/staff'
import { useAuth } from 'src/app/modules/auth'

const EventsSingleCheckinPage: FC<IEventSingleCheckinPage> = (props) => {
  const navigate = useNavigate()

  const { config } = useLayout()
  const { currentUser } = useAuth()

  const [selectedGuest, setSelectedGuest] = useState<IEventGuestData | null>(
    null
  )

  const {
    eventId,
    guestList,
    eventData,
    refetchEventGuestsList,
    isLoadingEventGuestsList,
    setPageGuestList,
    setGuestSearch,
    setLastGuestSearch,
    guestSearch,
    listCheckinMutate,
    accountSlug,
    userInfo,
    setSearchActive,
    searchActive,
    setGuestList,
    setShouldResetResults,
  } = props

  const changeCheckinStatus = (
    guest: IEventGuestData,
    statusChange: string
  ) => {
    const data = {
      accountSlug,
      listId: guest.list_id,
      userId: guest.user_id,
      statusId: statusChange,
      refetchFn: refetchEventGuestsList,
    }

    listCheckinMutate(data)
    setPageGuestList(1)
    closeCancelCheckinModal()
    setShouldResetResults(false)
  }

  const [backButtonLink, setBackButtonLink] = useState('')

  useEffect(() => {
    document.querySelector('.drawer-overlay')?.remove()
  }, [])

  useEffect(() => {
    setBackButtonLink(
      currentUser?.admLevelId === AdmLevelId.hostess
        ? EVENTS_ROUTE
        : DAHSBOARD_BY_EVENT_ID_ROUTE.replace(
            ':eventId',
            eventId.toString() || 'null'
          )
    )
  }, [currentUser, eventId])

  if (!config.app?.toolbar?.display) {
    return null
  }

  const openCancelCheckinModal = () => {
    document.body.classList.add('modal-open')
    document.body.classList.add('modal-cancel-checkin-show')
  }

  const closeCancelCheckinModal = () => {
    document.body.classList.remove('modal-open')
    document.body.classList.remove('modal-cancel-checkin-show')
  }

  const handleSearchChange = (event: { target: { value: any } }) => {
    const targetValue = event.target.value
    setGuestList([])
    setGuestSearch(targetValue)
    setLastGuestSearch(targetValue)
    setPageGuestList(1)
    setSearchActive(targetValue !== '')
  }

  return (
    <>
      {/* MODAL CANCEL CHECKIN */}
      <div className='modal_container modal-cancel-checkin-show'>
        <div
          className={`modal fade show d-block ${selectedGuest ? 'show' : ''}`}
          id='kt_modal_add_promoter'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          {/* begin::Modal dialog */}
          <div className='modal-dialog modal-dialog-centered mw-500px'>
            {/* begin::Modal content */}
            <div className='modal-content'>
              {/* begin::Modal body */}
              <div className='modal-body scroll-y mb-5 mx-xl-15 my-7'>
                {/* BODY SEARCH PROMOTERS */}
                {selectedGuest && (
                  <>
                    <h1 className='text-center nb-5'>
                      Você confirma o cancelamento do check-in de:
                    </h1>
                    <h2 className='text-center text-gray-500'>
                      {selectedGuest.fullname}
                    </h2>
                    <div
                      className='card-footer pt-8'
                      id='kt_chat_contacts_footer'
                    >
                      <div className='d-flex flex-stack pt-0'>
                        <div className='mr-2'>
                          <button
                            className={`btn btn-danger me-3`}
                            onClick={closeCancelCheckinModal}
                          >
                            <i className='ki-duotone ki-arrow-left fs-4'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                            Cancelar
                          </button>
                        </div>
                        <div>
                          <button
                            type='submit'
                            className='btn btn-success'
                            onClick={() => {
                              changeCheckinStatus(selectedGuest, '0')
                            }}
                          >
                            <span className='indicator-label'>
                              Confirmar
                              <i className='ki-duotone ki-double-check fs-2 ms-2 mb-0 me-0'>
                                <span className='path1' />
                                <span className='path2' />
                              </i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* BODY SEARCH PROMOTERS */}
              </div>
              {/* end::Modal body */}
            </div>
            {/* end::Modal content */}
          </div>
          {/* end::Modal dialog */}
        </div>
        {/* begin::Modal Backdrop */}
        {selectedGuest && (
          <div
            className='modal-backdrop fade show'
            id='kt_modal_add_promoter_bg'
          ></div>
        )}
        {/* end::Modal Backdrop */}
      </div>
      {/* MODAL CANCEL CHECKIN */}

      <div className='container_checkin'>
        <div className='container_checkin__header justify-content-between px-5 '>
          <div className='container_checkin__title d-flex align-items-center'>
            <button
              className='menu-link without-sub d-flex align-items-center gap-1 mx-16 button-style-reseted'
              onMouseUp={() => {
                if (window.innerWidth <= 992) {
                  window.location.replace(backButtonLink)
                  return
                }

                navigate(backButtonLink)
              }}
            >
              <span className='menu-icon'>
                <KTIcon iconName={'double-left'} className='fs-2' />
              </span>
              <span className='text-white'>Voltar</span>
            </button>
            <div className='d-flex align-items-center'>
              {eventData?.event_flyer && (
                <div className='symbol symbol-30px me-5'>
                  <img
                    src={eventData?.event_flyer_url || ''}
                    className=''
                    alt=''
                  />
                </div>
              )}
              <h1 className='text-light fs-3 mb-0'>
                {eventData?.event_name || '-'}
              </h1>
            </div>
          </div>
          <div className='container_checkin__event_info text-light d-flex align-items-center justify-content-center'>
            <div className='container_checkin__user d-none d-sm-flex align-items-center'>
              <div
                className='btn btn-icon btn-sm'
                data-kt-users-modal-action='close'
                style={{ cursor: 'pointer' }}
              >
                <KTIcon iconName='security-user' className='fs-1' />
              </div>
              {userInfo || '-'}
            </div>
            <div className='container_checkin__guests ms-5'>
              <div
                className='btn btn-icon btn-sm'
                data-kt-users-modal-action='close'
                style={{ cursor: 'pointer' }}
              >
                <KTIcon iconName='profile-user' className='fs-1' />
              </div>
              {eventData?.qtt_guests || '0'}
            </div>
          </div>
        </div>

        <div className='container_checkin__middle d-flex flex-column align-items-center p-10 p-lg-20'>
          <div className='search_container w-100 mw-1000px mx-auto d-flex flex-column flex-lg-row align-items-center'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-backstage.svg')}
              className='h-35px mb-5 mb-lg-0 me-lg-10'
            />
            <form className='w-100 position-relative' autoComplete='off'>
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder='Procure pelo nome...'
                value={guestSearch}
                onChange={handleSearchChange}
                onFocus={() => setShouldResetResults(true)}
              />
            </form>
          </div>

          <div className='guests_container mt-10 w-100 mw-1000px mx-auto d-flex flex-column flex-lg-row align-items-center'>
            <div className='table-responsive w-100 text-light'>
              {isLoadingEventGuestsList ? (
                <h1 className='text-light mb-0 text-center'>Carregando...</h1>
              ) : (
                <>
                  {searchActive && guestList.length > 0 ? (
                    <table
                      id='checkin-list'
                      className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
                    >
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='w-15 text-end'>Ações</th>
                          {/* Removido na versão do MVP, visto que não temos o registro de imagem até o momento */}
                          {/* <th className='w-10'>Avatar</th> */}
                          <th className='min-w-140px'>Convidado</th>
                          <th className='w-20'>Lista</th>
                          <th className='w-15'>Promoter</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guestList?.map((guest: IEventGuestData) => (
                          <tr
                            className={
                              guest.checkin_status === CheckInStatus.done
                                ? 'opacity-50'
                                : ''
                            }
                            key={generateKey()}
                            onClick={() => {
                              setSelectedGuest(guest)

                              if (guest.checkin_status === CheckInStatus.done) {
                                openCancelCheckinModal()
                                return
                              }

                              changeCheckinStatus(guest, '1')
                            }}
                          >
                            <td>
                              {guest?.checkin_status === CheckInStatus.done ? (
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                    title='Cancelar Checkin'
                                    className='btn btn-icon btn-danger btn-sm'
                                  >
                                    <KTIcon
                                      iconName='cross-circle'
                                      className='fs-1'
                                    />
                                  </button>
                                </div>
                              ) : (
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                    title='Checkin'
                                    className='btn btn-icon btn-success btn-sm'
                                  >
                                    <KTIcon
                                      iconName='double-check'
                                      className='fs-1'
                                    />
                                  </button>
                                </div>
                              )}
                            </td>
                            {/* Removido na versão do MVP, visto que não temos o registro de imagem até o momento */}
                            {/* <td>
                              <div className='symbol symbol-40px me-5'>
                                <img
                                  src={toAbsoluteUrl(
                                    '/media/avatars/blank.png'
                                  )}
                                  className=''
                                  alt=''
                                />
                              </div>
                            </td> */}
                            <td>
                              <div className='d-inline-flex align-items-center'>
                                <div className='d-inline-flex justify-content-start flex-column'>
                                  <button
                                    className={`fw-bold text-light ttu fs-6 button-style-reseted 
                                     ${
                                       guest?.checkin_status ===
                                       CheckInStatus.done
                                         ? 'tdlt'
                                         : ''
                                     }`}
                                  >
                                    {guest?.fullname || '-'}
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className='badge justify-content-center mw-100 mx-0 py-2 px-4 text-center ttu text-light fs-8 button-style-reseted'
                                style={{
                                  backgroundColor: '#0996bc',
                                  whiteSpace: 'normal', //TODO: Remover quando voltar com avatar
                                  lineHeight: '1rem', //TODO: Remover quando voltar com avatar
                                }}
                              >
                                <span>{guest?.list_name || '-'}</span>
                              </button>
                            </td>
                            <td className=''>
                              <button className='d-inline-flex justify-content-start flex-column button-style-reseted'>
                                <span className='fw-bold text-light ttu fs-6'>
                                  {guest?.adm_user_fullname || '-'}
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : searchActive ? (
                    <h1 className='text-light mb-0 text-center'>
                      Sem dados para exibir.
                    </h1>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EventSingleCheckin: FC = () => {
  const intl = useIntl()
  const {
    eventInfoData,
    eventGuestsListData,
    refetchEventGuestsList,
    isLoadingEventGuestsList,
    setEventGuestsListFilter,
    eventGuestListFilter,
    accountSlug,
    eventId,
    listCheckinMutate,
    userId,
  } = useEventSingleCheckin()
  const [guestList, setGuestList] = useState<IEventGuestData[]>([])
  const [eventData, setEventData] = useState<IEventInfoData>()
  const [pageGuestList, setPageGuestList] = useState<number>(1)
  const [guestSearch, setGuestSearch] = useState<string>('')
  const [lastGuestSearch, setLastGuestSearch] = useState<string>('')
  const [maxPage, setMaxPage] = useState<number>(1)
  const [userInfo, setUserInfo] = useState<string>('')
  const [searchActive, setSearchActive] = useState(false)
  const [shouldResetResults, setShouldResetResults] = useState<boolean>(true)

  useEffect(() => {
    if (
      !eventGuestsListData ||
      (eventGuestsListData && eventGuestsListData.guests.length <= 0) ||
      (eventGuestsListData &&
        eventGuestsListData.totalResults > 0 &&
        eventGuestsListData.guests === guestList) ||
      (guestSearch === '' && shouldResetResults)
    )
      return

    if (pageGuestList !== 1) {
      let guestsFormattedList: any = new Set([
        ...guestList,
        ...eventGuestsListData.guests,
      ])
      guestsFormattedList = Array.from(guestsFormattedList)

      setGuestList(guestsFormattedList)
      return
    }

    setGuestList(eventGuestsListData.guests)
  }, [eventGuestsListData, guestList, guestSearch, maxPage, pageGuestList])

  useEffect(() => {
    if (eventInfoData !== null && eventInfoData !== undefined) {
      setEventData(eventInfoData)
    }
  }, [eventInfoData])

  useEffect(() => {
    if (!shouldResetResults) {
      setGuestSearch('')
    }

    setPageGuestList(1)
  }, [shouldResetResults])

  useEffect(() => {
    let filter = {
      ...eventGuestListFilter,
      page: pageGuestList,
      accountSlug,
      eventId,
      reset: shouldResetResults,
    }

    filter.search = shouldResetResults
      ? guestSearch
      : lastGuestSearch.toUpperCase()

    if (shouldResetResults) {
      setGuestList([])
    }

    setEventGuestsListFilter(filter)
  }, [
    accountSlug,
    eventGuestListFilter,
    eventId,
    guestSearch,
    lastGuestSearch,
    pageGuestList,
    setEventGuestsListFilter,
    shouldResetResults,
  ])

  useEffect(() => {
    const scrollElement =
      document.querySelector('.container_checkin__middle') || null
    if (scrollElement === null) return

    const handleScroll = () => {
      if (
        !eventGuestsListData ||
        eventGuestsListData.length <= 0 ||
        !eventGuestsListData.totalResults
      )
        return

      const expectedMaxPage = Math.ceil(eventGuestsListData.totalResults / 20) // 20 é a quantidade maxima de itens por pagina
      setMaxPage(expectedMaxPage)
      if (expectedMaxPage <= pageGuestList) {
        return
      }
      setPageGuestList(pageGuestList + 1)
    }

    scrollElement.addEventListener('scroll', () => {
      handleScroll()
    })
  }, [eventGuestsListData, pageGuestList])

  useEffect(() => {
    if (userId && eventData) {
      eventData.events_levels?.forEach((eventLevel) => {
        eventLevel.users?.forEach((user) => {
          if (user.adm_user_id === userId) {
            setUserInfo(`${user.fullname} (${eventLevel.level})`)
          }
        })
      })
    }
  }, [userId, eventData])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.EVENTS' })}
      </PageTitle>
      <EventsSingleCheckinPage
        eventId={eventId}
        guestList={guestList}
        eventData={eventData as unknown as IEventInfoData}
        refetchEventGuestsList={refetchEventGuestsList}
        isLoadingEventGuestsList={isLoadingEventGuestsList}
        setPageGuestList={setPageGuestList}
        setGuestSearch={setGuestSearch}
        setLastGuestSearch={setLastGuestSearch}
        guestSearch={guestSearch as string}
        listCheckinMutate={listCheckinMutate}
        accountSlug={accountSlug}
        userInfo={userInfo}
        setSearchActive={setSearchActive}
        searchActive={searchActive}
        setGuestList={setGuestList}
        setShouldResetResults={setShouldResetResults}
      />
    </>
  )
}

export { EventSingleCheckin }
