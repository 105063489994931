import { FC } from 'react'
import { Link } from 'react-router-dom'
import { EVENTS_ROUTE } from 'src/constants/routes'

const Error500: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-1hx text-gray-900 mb-4'>Erro no Sistema!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        Algo deu errado! <br />
        Tente novamente mais tarde.
      </div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-0'>
        {/* <Link to={DAHSBOARD_ROUTE} className='btn btn-sm btn-primary'> */}
        <Link to={EVENTS_ROUTE} className='btn btn-sm btn-primary'>
          Voltar para Home
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export { Error500 }
