import * as Yup from 'yup'

export const registrationSchema = Yup.object().shape({
  slug: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Usuário é obrigatório'),
  fullname: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Nome Completo é obrigatório'),
  email: Yup.string()
    .email('Formato de e-mail inválido')
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Email é obrigatório'),
  birthdate: Yup.string()
    .min(10, 'Mínimo de 10 caracteres')
    .max(10, 'Máximo de 10 caracteres')
    // .matches(
    //   /^(0?[1-9]|[1-2]\d|3[0-1])\/(0?[1-9]|1[0-2])\/\d{4}$/,
    //   'Data de Nascimento precisa estar no formato DD/MM/YYYY'
    // )
    .required('Data de Nascimento é obrigatório'),
  phone: Yup.string()
    .matches(
      /^[0-9]+$/,
      'Por favor, insira apenas números sem espaços e caracteres especiais'
    )
    .min(11, 'Mínimo de 11 caracteres')
    .max(11, 'Máximo de 11 caracteres')
    .required('Telefone é obrigatório'),
  password: Yup.string()
    .min(3, 'Mínimo de 8 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Senha é obrigatório')
    .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/, {
      message:
        'Use 8 ou mais caracteres, com o mix de letra maiúscula, número e símbolo.',
    }),
  changePassword: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Confirmar a senha é obrigatório')
    .oneOf([Yup.ref('password')], 'Senhas não são iguais'),
})
