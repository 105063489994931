/* eslint-disable react/jsx-no-target-blank */
import { KTIcon } from '../../../helpers'
import { useAuth } from '../../../../app/modules/auth'

const SidebarFooter = () => {
  const { logout } = useAuth()
  return (
    <div
      className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6'
      id='kt_app_sidebar_footer'
    >
      <a
        href='#'
        target='_self'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Logout'
        onClick={logout}
      >
        <span className='btn-label'>Logout</span>
        <KTIcon iconName='exit-left' className='btn-icon fs-2 m-0' />
      </a>
    </div>
  )
}

export { SidebarFooter }
