import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'

import { camelCaseToSnakeCase } from 'src/resources/utils/camelCase'
import { IServiceResponse } from '../../resources/types/services'
import {
  IDeleteAdmUserParams,
  IUpdatePassworOrEmaildParams,
  IUpdateProfileAdmParams,
  IForgotPasswordSendTokenToEmailParams,
  IForgotPasswordSendTokenToEmailResponse,
  IForgotPasswordUpdateByTokenParams,
} from '../../resources/types/services/account'

export const AccountService = {
  sendTwoFactore: async (data: any): Promise<any> => {
    const api = getApiInstance()

    const dataToSnakeCase = {
      // action: 'registerUser',
      action_pt: 'pt1',
      ...camelCaseToSnakeCase(data),
      birthdate: dayjs(data.birthdate).format('DD/MM/YYYY'),
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.register,
      dataToSnakeCase
    )

    return response?.data
  },

  register: async (data: any): Promise<any> => {
    const api = getApiInstance()

    const dataToSnakeCase = {
      // action: 'registerUser',
      action_pt: 'pt2',
      ...camelCaseToSnakeCase(data),
      birthdate: dayjs(data.birthdate).format('DD/MM/YYYY'),
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.register,
      dataToSnakeCase
    )

    return response?.data
  },

  updateProfileDetails: async (
    params: IUpdateProfileAdmParams
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'updateAdmUser',
      ...camelCaseToSnakeCase(params),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.updateProfileAdm,
      data
    )
    return response?.data
  },

  updatePassword: async (
    params: IUpdatePassworOrEmaildParams
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()

    const data = {
      action: 'updatePwd',
      ...camelCaseToSnakeCase(params),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.updatePasswordAdm,
      data
    )
    return response?.data
  },

  deleteAdmUser: async (
    params: IDeleteAdmUserParams
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'deleteAdmUser',
      ...camelCaseToSnakeCase(params),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.deleteAdmUser,
      data
    )
    return response?.data
  },

  forgotPasswordSendTokenToEmail: async (
    data: IForgotPasswordSendTokenToEmailParams
  ): Promise<IForgotPasswordSendTokenToEmailResponse> => {
    const api = getApiInstance()

    const dataToSnakeCase = {
      action: 'sendPwdTokenMail',
      ...camelCaseToSnakeCase(data),
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.forgotPasswordSendTokenToEmail,
      dataToSnakeCase
    )

    return response?.data
  },

  forgotPasswordUpdateByToken: async (
    data: IForgotPasswordUpdateByTokenParams
  ): Promise<any> => {
    const api = getApiInstance()

    const dataToSnakeCase = {
      action: 'updatePwdByToken',
      ...data,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.forgotPasswordUpdateByToken,
      dataToSnakeCase
    )

    return response?.data
  },
}
