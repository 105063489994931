import { AxiosResponse } from 'axios'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'
import {
  IAddressByCepResponse,
  ILocationStatesResponse,
  ILocationCitiesResponse,
} from 'src/resources/types/services/location'

export const LocationService = {
  getAddressByCep: async (cep: number): Promise<IAddressByCepResponse> => {
    const api = getApiInstance()

    let data = {
      action: 'getAddressByCep',
      cep,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.public.address.getByCep,
      data
    )

    return response?.data
  },

  getStates: async (): Promise<ILocationStatesResponse> => {
    const api = getApiInstance()

    let data = {
      action: 'getStates',
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.public.address.getStates,
      data
    )

    return response?.data
  },

  getCitiesByState: async (
    stateId: number
  ): Promise<ILocationCitiesResponse> => {
    const api = getApiInstance()

    let data = {
      action: 'getCitiesByState',
      state_id: stateId,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.public.address.getStates,
      data
    )

    return response?.data
  },
}
