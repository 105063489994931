import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { EVENTS_ROUTE } from 'src/constants/routes'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* Ocultado temporariamente o link para o Dashboard Geral no menu lateral para o MVP */}
      {/* <MenuItem
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        to={DAHSBOARD_ROUTE}
      /> */}

      <MenuItem
        title={intl.formatMessage({ id: 'MENU.EVENTS' })}
        to={EVENTS_ROUTE}
      />

      {/* <MenuItem title='Layout Builder' to={BUILDER_ROUTE} /> */}

      {/* 
      <MenuInnerWithSub
        title='Crafted'
        to={CRAFTED_ROUTE}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='Pages'
          to={CRAFTED_PAGES_ROUTE}
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to={CRAFTED_PROFILE_ROUTE}
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to={CRAFTED_PROFILE_OVERVIEW_ROUTE} title='Overview' hasBullet={true} />
            <MenuItem to={CRAFTED_PROFILE_PROJECTS_ROUTE} title='Projects' hasBullet={true} />
            <MenuItem to={CRAFTED_PROFILE_CAMPAIGNS_ROUTE} title='Campaigns' hasBullet={true} />
            <MenuItem to={CRAFTED_PROFILE_DOCUMENTS_ROUTE} title='Documents' hasBullet={true} />
            <MenuItem
              to={CRAFTED_PROFILE_CONNECTIONS_ROUTE}
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to={CRAFTED_WIZARDS_ROUTE}
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to={CRAFTED_WIZARDS_GENERIC_HORIZONTAL_ROUTE}title='Horizontal' hasBullet={true} />
            <MenuItem to={CRAFTED_WIZARDS_GENERIC_VERTICAL_ROUTE} title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub> */}

      {/* ACCOUNT */}
      {/* <MenuInnerWithSub
          title='Accounts'
          to={CRAFTED_ACCOUNTS_GENERIC_ROUTE}
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to={CRAFTED_ACCOUNT_OVERVIEW_ROUTE} title='Overview' hasBullet={true} />
          <MenuItem to={CRAFTED_ACCOUNT_SETTINGS_ROUTE} title='Settings' hasBullet={true} />
        </MenuInnerWithSub> */}

      {/* ERRORS */}
      {/* <MenuInnerWithSub
          title='Errors'
          to={ERROR_ROUTE}
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to={ERROR_404_ROUTE} title='Error 404' hasBullet={true} />
          <MenuItem to={ERROR_500_ROUTE} title='Error 500' hasBullet={true} />
        </MenuInnerWithSub> */}

      {/* Widgets */}
      {/* <MenuInnerWithSub
          title='Widgets'
          to={CRAFTED_WIDGETS_ROUTE}
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to={CRAFTED_WIDGETS_LISTS_ROUTE} title='Lists' hasBullet={true} />
          <MenuItem to={CRAFTED_WIDGETS_STATISTICS_ROUTE} title='Statistics' hasBullet={true} />
          <MenuItem to={CRAFTED_WIDGETS_CHARTS_ROUTE} title='Charts' hasBullet={true} />
          <MenuItem to={CRAFTED_WIDGETS_MIXED_ROUTE} title='Mixed' hasBullet={true} />
          <MenuItem to={CRAFTED_WIDGETS_TABLES_ROUTE} title='Tables' hasBullet={true} />
          <MenuItem to={CRAFTED_WIDGETS_FEEDS_ROUTE} title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}

      {/* 
      <MenuInnerWithSub title='Apps' to={APPS_ROUTE} menuPlacement='bottom-start' menuTrigger='click'>
        <MenuInnerWithSub
          title='Chat'
          to={CHAT_ROUTE}
          icon='message-text-2'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to={CHAT_PRIVATE_ROUTE} title='Private Chat' hasBullet={true} />
          <MenuItem to={CHAT_GROUP_ROUTE} title='Group Chart' hasBullet={true} />
          <MenuItem to={CHAT_DRAWER_ROUTE} title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem icon='abstract-28' to={USER_MANAGMENT_USERS_ROUTE}title='User management' />
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to={MEGA_MENU_TEST_ROUTE}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
