import { IUseEventSingleGuests } from 'src/resources/types/hooks/guests'
import { Dispatch, SetStateAction } from 'react'
import { IntlShape } from 'react-intl'
import { IServiceResponse } from '@resources/types/services'
import { UseMutateFunction } from 'react-query'

export enum CheckInStatus {
  notDone = '0',
  done = '1',
}

export interface IEventsSingleGuestsPage extends IUseEventSingleGuests {
  initialResultsPage: number
  initialResultsItem: number
  finalResultsItem: number
  eventGuestsListDataTotalPages: number
  searchGuestInputValue: string
  setSearchGuestInputValue: Dispatch<SetStateAction<string>>
  searchEventListsInputValue: string
  setSearchEventListsInputValue: Dispatch<SetStateAction<string>>
  appliedCheckinStatusFilter: number | 'all'
  setAppliedCheckinStatusFilter: Dispatch<SetStateAction<number | 'all'>>
  appliedEventListIdFilter: number | 'all'
  setAppliedEventListIdFilter: Dispatch<SetStateAction<number | 'all'>>
  appliedPromoterIdFilter: number | 'all'
  setAppliedPromoterIdFilter: Dispatch<SetStateAction<number | 'all'>>
  pageEventGuestsList: number
  setPageEventGuestsList: Dispatch<SetStateAction<number>>
  intl: IntlShape
}

export interface IEventsSingleAddGuestsPage {
  recaptchaToken: string
  addGuestAsPublicUserMutate: UseMutateFunction<
    IServiceResponse,
    any,
    any,
    unknown
  >
  closeAddGuestsModal: () => void
}

export interface IActiveFilters {
  checkinStatus: number | 'all'
  list: number | 'all'
  admUserId: number | 'all'
}

export interface IEditGuestListId {
  current: number | null
  selected: number | null
}

export interface IEventPromotersAndManagersSelectOptions {
  fullname: string
  adm_user_id: number
}
