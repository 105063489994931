import InputMask from 'react-input-mask'

import { IProps } from './types'

const FieldCpf = ({ field, formik, ...props }: IProps) => {
  return (
    <div className='fv-row mb-10'>
      {/*begin::Label*/}
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={field.mandatory === '1' ? 'required' : ''}>
          {field.register_field_name}
        </span>
        <span
          className='ms-1'
          data-bs-toggle='tooltip'
          title={`Digite o(a) ${field.register_field_name}`}
        >
          {field.mandatory === '1' && (
            <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
              <span className='path1' />
              <span className='path2' />
              <span className='path3' />
            </i>
          )}
        </span>
      </label>
      {/*end::Label*/}
      {/*begin::Input*/}
      <InputMask
        mask={'999.999.999-99'}
        className={`form-control form-control-lg form-control-solid pt-3 ${
          formik.touched.cpf && formik.errors.cpf ? 'is-invalid' : ''
        }`}
        placeholder='Digite o CPF'
        {...props}
      />

      {formik.touched.cpf && formik.errors.cpf && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors.cpf}</div>
        </div>
      )}
      {/*end::Input*/}
    </div>
  )
}

export default FieldCpf
