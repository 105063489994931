import * as Yup from 'yup'

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Mínimo de 8 caracteres')
    .max(20, 'Máximo de 20 caracteres')
    .required('Senha é obrigatório')
    .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/, {
      message:
        'Use 8 ou mais caracteres, com o mix de letra maiúscula, número e símbolo.',
    }),
  changePassword: Yup.string()
    .min(8, 'Mínimo de 8 caracteres')
    .max(20, 'Máximo de 20 caracteres')
    .required('Confirmar a senha é obrigatório')
    .oneOf([Yup.ref('password')], 'Senhas não são iguais'),
})
