import { FC } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { checkIsActive, KTIcon, WithChildren } from '../../../../helpers'
import { useLayout } from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  ignoreActive?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  ignoreActive = false,
}) => {
  const navigate = useNavigate()

  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { app } = config
  // eslint-disable-next-line no-undef
  let content: JSX.Element

  if (ignoreActive) {
    content = (
      <button
        className={clsx('menu-link without-sub') + ' button-style-reseted'}
        onMouseUp={() => navigate(to)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </button>
    )
  } else {
    content = (
      <button
        className={
          clsx('menu-link without-sub', { active: isActive }) +
          ' button-style-reseted'
        }
        onMouseUp={() => navigate(to)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </button>
    )
  }

  return (
    <button className='menu-item button-style-reseted'>
      {content}
      {children}
    </button>
  )
}

export { SidebarMenuItem }
