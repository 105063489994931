/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from 'react-router-dom'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'
import { ErrorsLayout } from './ErrorsLayout'
import { GENERIC_400_ROUTE, GENERIC_500_ROUTE } from 'src/constants/routes'

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path={GENERIC_400_ROUTE} element={<Error404 />} />
      <Route path={GENERIC_500_ROUTE} element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export { ErrorsPage }
