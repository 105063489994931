import React from 'react'
import clsx from 'clsx'
import { useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
// import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import {
  EVENTS_LIST_ROUTE,
  EVENTS_NEW_ROUTE,
  EVENTS_ROUTE,
  EVENT_CHECKIN_ROUTE,
} from 'src/constants/routes'
import { AdmLevelId } from 'src/resources/types/pages/staff'
import { useAuth } from 'src/app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const location = useLocation()

  const { currentUser } = useAuth()
  const { eventId } = useParams()

  const currentUserAdmnLevelId: AdmLevelId | null =
    currentUser?.admLevelId || null
  const main_class = location['pathname'].startsWith('/event/') ? true : false
  const single_class = !location['pathname'].startsWith('/event/')
    ? true
    : false

  // console.log(location)
  // console.log(main_class)
  // console.log(single_class)

  return (
    <>
      <div
        className={clsx('main-menu menu-container px-3', { hide: main_class })}
      >
        {/* <div className="main-menu menu-container px-3"> */}

        {/* Ocultado temporariamente o link para o Dashboard Geral no menu lateral para o MVP */}
        {/* <SidebarMenuItem
          to={DAHSBOARD_ROUTE}
          icon='element-11'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD.GENERAL' })}
          fontIcon='bi-app-indicator'
        /> */}

        <SidebarMenuItemWithSub
          to={EVENTS_ROUTE}
          title='Eventos'
          fontIcon='bi-app-indicator'
          icon='calendar-tick'
        >
          <SidebarMenuItem
            to={EVENTS_LIST_ROUTE}
            title='Todos os eventos'
            hasBullet={true}
          />
          {/* À pedido de Negócio, foi solicitado, em 23/08/2023, que todos os usuários possam criar evento. A partir do momento que o produto for pago, ajustar para que somente a conta de produtora possa. */}
          {/* {currentUser?.hasAdminPermission && ( */}
          <SidebarMenuItem
            to={EVENTS_NEW_ROUTE}
            title='Novo Evento'
            hasBullet={true}
          />
          {/* )} */}
        </SidebarMenuItemWithSub>

        {/* 
				<SidebarMenuItemWithSub
					to={LISTS_ROUTE}
					icon='questionnaire-tablet'
					title={intl.formatMessage({ id: 'MENU.EVENT.LISTS.TEMPLATES' })}
					fontIcon='bi-app-indicator'
				>
					<SidebarMenuItem to={LISTS_LIST_ROUTE} title={intl.formatMessage({ id: 'MENU.EVENT.LISTS.TEMPLATES' })} hasBullet={true} />
					<SidebarMenuItem to={LISTS_NEW_ROUTE} title='Nova Lista' hasBullet={true} />
				</SidebarMenuItemWithSub>

				<SidebarMenuItemWithSub
					to={PROMOTERS_LIST_ROUTE}
					icon='security-user'
					title={intl.formatMessage({ id: 'MENU.EVENT.PROMOTERS' })}
					fontIcon='bi-app-indicator'
				>
					<SidebarMenuItem to={PROMOTERS_LIST_ROUTE} title={intl.formatMessage({ id: 'MENU.EVENT.PROMOTERS' })} hasBullet={true} />
					<SidebarMenuItem to={PROMOTERS_NEW_ROUTE} title='Convidar Promoter' hasBullet={true} />
				</SidebarMenuItemWithSub>
				*/}
      </div>

      <div className={clsx('single-menu', { hide: single_class })}>
        <div className='menu-container px-3'>
          <SidebarMenuItem
            // to={DAHSBOARD_ROUTE}
            to={EVENTS_ROUTE}
            icon='double-left'
            title='Voltar'
            fontIcon='bi-app-indicator'
            ignoreActive={true}
          />
        </div>

        <div className='menu-item'>
          <div className='menu-content py-3'></div>
        </div>

        <div className='menu-item'>
          <div className='menu-content'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
              Administrar Evento
            </span>
          </div>
        </div>

        <div className='secondary-menu px-3'>
          {(currentUserAdmnLevelId === AdmLevelId.producer ||
            currentUserAdmnLevelId === AdmLevelId.manager ||
            currentUserAdmnLevelId === AdmLevelId.promoter) && (
            <SidebarMenuItem
              to={`/event/${eventId}/dashboard`}
              icon='calendar-tick'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-app-indicator'
            />
          )}

          {(currentUserAdmnLevelId === AdmLevelId.producer ||
            currentUserAdmnLevelId === AdmLevelId.manager) && (
            <SidebarMenuItem
              to={`/event/${eventId}/edit`}
              icon='calendar-edit'
              title={intl.formatMessage({ id: 'MENU.EDIT.EVENT' })}
              fontIcon='bi-app-indicator'
            />
          )}

          {(currentUserAdmnLevelId === AdmLevelId.producer ||
            currentUserAdmnLevelId === AdmLevelId.manager) && (
            <SidebarMenuItem
              to={`/event/${eventId}/staff`}
              icon='security-user'
              title={intl.formatMessage({ id: 'MENU.EVENT.STAFF' })}
              fontIcon='bi-app-indicator'
            />
          )}

          {(currentUserAdmnLevelId === AdmLevelId.producer ||
            currentUserAdmnLevelId === AdmLevelId.manager ||
            currentUserAdmnLevelId === AdmLevelId.promoter) && (
            <SidebarMenuItem
              to={`/event/${eventId}/lists`}
              icon='questionnaire-tablet'
              title={intl.formatMessage({ id: 'MENU.EVENT.LISTS' })}
              fontIcon='bi-app-indicator'
            />
          )}

          {(currentUserAdmnLevelId === AdmLevelId.producer ||
            currentUserAdmnLevelId === AdmLevelId.manager ||
            currentUserAdmnLevelId === AdmLevelId.promoter) && (
            <SidebarMenuItem
              to={`/event/${eventId}/guests`}
              icon='profile-user'
              title={intl.formatMessage({ id: 'MENU.GUESTS' })}
              fontIcon='bi-app-indicator'
            />
          )}

          {(currentUserAdmnLevelId === AdmLevelId.producer ||
            currentUserAdmnLevelId === AdmLevelId.manager ||
            currentUserAdmnLevelId === AdmLevelId.hostess) && (
            <SidebarMenuItem
              to={EVENT_CHECKIN_ROUTE.replace(':eventId', eventId || 'null')}
              icon='double-check'
              title='Check-In'
              fontIcon='double-check'
            />
          )}

          {/* 
						<SidebarMenuItemWithSub
							to={`/event/${eventId}/guests`}
							icon='profile-user'
							title={intl.formatMessage({ id: 'MENU.GUESTS' })}
							fontIcon='bi-app-indicator'
						>
							<SidebarMenuItem to={`/event/${eventId}/guests`} title={intl.formatMessage({ id: 'MENU.LIST.GUESTS' })} hasBullet={true} />
							<SidebarMenuItem to={`/event/${eventId}/add-guests' title={intl.formatMessage({ id: 'MENU.ADD.GUESTS' })} hasBullet={true} />
						</SidebarMenuItemWithSub>
						*/}
        </div>
      </div>
    </>
  )
}

export { SidebarMenuMain }
