import { IFormatDateAndTimeStringArgs } from 'src/resources/types/utils/dateAndTime'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

export const getHoursDifferenceBetweenDates = (
  endDate: Date,
  beginDate: Date
) => {
  var diff = (endDate.getTime() - beginDate.getTime()) / 1000
  diff /= 60 * 60
  return Math.abs(diff)
}

export const validateEventDatesAndTimes = (props: {
  beginDateAndTime: string
  endDateAndTime: string
  action: 'createEvent' | 'editEvent'
  hideErrorMessage?: boolean
}) => {
  const { beginDateAndTime, endDateAndTime, action, hideErrorMessage } = props

  const formattedBeginDateAndTime: Date = new Date(beginDateAndTime)
  const formattedEndDateAndTime: Date = new Date(endDateAndTime)
  const nowDateAndTime: Date = new Date()

  // Não permitir seleção de datas do passado
  const hasDateAndTimeInPast: boolean =
    formattedBeginDateAndTime < nowDateAndTime ||
    formattedEndDateAndTime < nowDateAndTime

  // Não permitir eventos com mais de 24 horas de dureção
  const hasInvalidPeriod: boolean =
    getHoursDifferenceBetweenDates(
      formattedEndDateAndTime,
      formattedBeginDateAndTime
    ) > 24

  if ((action === 'createEvent' && hasDateAndTimeInPast) || hasInvalidPeriod) {
    if (!hideErrorMessage) {
      toast.error(
        `Por favor, selecione datas válidas. (Confira se seu evento possui data no passado e/ou duração maior que 24 horas.)`,
        {
          toastId: 'eventDateInPast',
        }
      )
    }

    return false
  }

  return true
}

export const formatAsddddDDMMYYYYHHhmm = (
  args: IFormatDateAndTimeStringArgs
): string => {
  const { dateAndTimeString, defaultValue } = args
  const isValidDate = dateAndTimeString && dayjs(dateAndTimeString).isValid()
  let formattedDateAndHour = isValidDate
    ? dayjs(dateAndTimeString).format('dddd [-] DD/MM/YYYY [às] HH[h]mm')
    : defaultValue

  if (!isValidDate) return formattedDateAndHour

  formattedDateAndHour =
    formattedDateAndHour[0].toUpperCase() + formattedDateAndHour.slice(1)

  formattedDateAndHour = formattedDateAndHour.replace('h00', 'h')

  return formattedDateAndHour
}

export const formatAsDDMMYYYYHHhmm = (
  args: IFormatDateAndTimeStringArgs
): string => {
  const { dateAndTimeString, defaultValue } = args
  const isValidDate = dateAndTimeString && dayjs(dateAndTimeString).isValid()
  let formattedDateAndHour = isValidDate
    ? dayjs(dateAndTimeString).format('DD/MM/YYYY [às] HH[h]mm')
    : defaultValue

  if (!isValidDate) return formattedDateAndHour

  formattedDateAndHour =
    formattedDateAndHour[0].toUpperCase() + formattedDateAndHour.slice(1)

  formattedDateAndHour = formattedDateAndHour.replace('h00', 'h')

  return formattedDateAndHour
}

export const formatAsDDMMHHhmm = (
  args: IFormatDateAndTimeStringArgs
): string => {
  const { dateAndTimeString, defaultValue } = args
  const isValidDate = dateAndTimeString && dayjs(dateAndTimeString).isValid()
  let formattedDateAndHour = isValidDate
    ? dayjs(dateAndTimeString).format('DD/MM HH[h]mm')
    : defaultValue

  if (!isValidDate) return formattedDateAndHour

  formattedDateAndHour = formattedDateAndHour.replace('h00', 'h')

  return formattedDateAndHour
}

export const formatAsHHhmmDDMM = (
  args: IFormatDateAndTimeStringArgs
): string => {
  const { dateAndTimeString, defaultValue } = args
  const isValidDate = dateAndTimeString && dayjs(dateAndTimeString).isValid()
  let formattedDateAndHour = isValidDate
    ? dayjs(dateAndTimeString).format('HH[h]mm DD/MM')
    : defaultValue

  if (!isValidDate) return formattedDateAndHour

  formattedDateAndHour = formattedDateAndHour.replace('h00', 'h')

  return formattedDateAndHour
}

export const formatAsDDMMYYYYHHmmss = (
  args: IFormatDateAndTimeStringArgs
): string => {
  const { dateAndTimeString, defaultValue } = args
  const isValidDate = dateAndTimeString && dayjs(dateAndTimeString).isValid()
  const formattedDateAndHour = isValidDate
    ? dayjs(dateAndTimeString).format('DD/MM/YYYY HH:mm:ss')
    : defaultValue

  return formattedDateAndHour
}

export const formatAsDDMMHHmm = (
  args: IFormatDateAndTimeStringArgs
): string => {
  const { dateAndTimeString, defaultValue } = args
  const isValidDate = dateAndTimeString && dayjs(dateAndTimeString).isValid()
  let formattedDateAndHour = isValidDate
    ? dayjs(dateAndTimeString).format('DD/MM HH:mm')
    : defaultValue

  return formattedDateAndHour
}
