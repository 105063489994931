/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  description: string
  subdescription: string
  className: string
}

const RankingPromoters: React.FC<Props> = ({
  description,
  subdescription,
  className,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-2'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>
            {description}
          </span>
          <span className='text-gray-400 mt-1 fw-bold fs-7'>
            {subdescription}
          </span>
        </h3>
        <div className='card-toolbar'>
          <a href='/promoters/list' className='btn btn-sm btn-light'>
            Ver
          </a>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-7'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-0'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img
              src={toAbsoluteUrl('/media/avatars/300-6.jpg')}
              className=''
              alt=''
            />
          </div>
          {/* end::Avatar */}
          {/* begin::Text */}
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Emma Smith
            </a>
            <a href='#' className='text-muted d-block fw-semibold'>
              <i className='ki-duotone ki-whatsapp'>
                <i className='path1'></i>
                <i className='path2'></i>
              </i>
              &nbsp;(11) 99999-5544
            </a>
          </div>
          <div className='d-flex align-items-senter'>
            {/* arrow up */}
            <i className='ki-duotone ki-arrow-up-right fs-2 text-success me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span className='text-gray-900 fw-bolder fs-6'>125</span>
            {/* <span className="text-gray-400 fw-bold fs-6">/10</span> */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}

        <div className='separator separator-dashed my-3'></div>

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-0'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img
              src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
              className=''
              alt=''
            />
          </div>
          {/* end::Avatar */}
          {/* begin::Text */}
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Sean Bean
            </a>
            <a href='#' className='text-muted d-block fw-semibold'>
              <i className='ki-duotone ki-whatsapp'>
                <i className='path1'></i>
                <i className='path2'></i>
              </i>
              &nbsp;(11) 99999-5544
            </a>
          </div>
          <div className='d-flex align-items-senter'>
            {/* arrow down */}
            <i className='ki-duotone ki-arrow-down-right fs-2 text-danger me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span className='text-gray-900 fw-bolder fs-6'>92</span>
            {/* <span className="text-gray-400 fw-bold fs-6">/10</span> */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}

        <div className='separator separator-dashed my-3'></div>

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-0'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img
              src={toAbsoluteUrl('/media/avatars/300-6.jpg')}
              className=''
              alt=''
            />
          </div>
          {/* end::Avatar */}
          {/* begin::Text */}
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Emma Smith
            </a>
            <a href='#' className='text-muted d-block fw-semibold'>
              <i className='ki-duotone ki-whatsapp'>
                <i className='path1'></i>
                <i className='path2'></i>
              </i>
              &nbsp;(11) 99999-5544
            </a>
          </div>
          <div className='d-flex align-items-senter'>
            {/* arrow up */}
            <i className='ki-duotone ki-arrow-up-right fs-2 text-success me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span className='text-gray-900 fw-bolder fs-6'>125</span>
            {/* <span className="text-gray-400 fw-bold fs-6">/10</span> */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}

        <div className='separator separator-dashed my-3'></div>

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-0'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img
              src={toAbsoluteUrl('/media/avatars/300-5.jpg')}
              className=''
              alt=''
            />
          </div>
          {/* end::Avatar */}
          {/* begin::Text */}
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Sean Bean
            </a>
            <a href='#' className='text-muted d-block fw-semibold'>
              <i className='ki-duotone ki-whatsapp'>
                <i className='path1'></i>
                <i className='path2'></i>
              </i>
              &nbsp;(11) 99999-5544
            </a>
          </div>
          <div className='d-flex align-items-senter'>
            {/* arrow down */}
            <i className='ki-duotone ki-arrow-down-right fs-2 text-danger me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span className='text-gray-900 fw-bolder fs-6'>92</span>
            {/* <span className="text-gray-400 fw-bold fs-6">/10</span> */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}

        <div className='separator separator-dashed my-3'></div>

        {/* begin::Item */}
        <div className='d-flex align-items-center mb-0'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img
              src={toAbsoluteUrl('/media/avatars/300-6.jpg')}
              className=''
              alt=''
            />
          </div>
          {/* end::Avatar */}
          {/* begin::Text */}
          <div className='flex-grow-1'>
            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
              Emma Smith
            </a>
            <a href='#' className='text-muted d-block fw-semibold'>
              <i className='ki-duotone ki-whatsapp'>
                <i className='path1'></i>
                <i className='path2'></i>
              </i>
              &nbsp;(11) 99999-5544
            </a>
          </div>
          <div className='d-flex align-items-senter'>
            {/* arrow up */}
            <i className='ki-duotone ki-arrow-up-right fs-2 text-success me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span className='text-gray-900 fw-bolder fs-6'>125</span>
            {/* <span className="text-gray-400 fw-bold fs-6">/10</span> */}
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { RankingPromoters }
