import { AxiosResponse } from 'axios'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'
import { camelCaseToSnakeCase } from 'src/resources/utils/camelCase'

import {
  IDeleteStaffUserArgs,
  IEventStaffInviteFilter,
  IUpdateStaffUserArgs,
} from 'src/resources/types/services/staff'
import { IServiceResponse } from 'src/resources/types/services'

export const StaffService = {
  getEventStaffInvitePage: async (
    filter: IEventStaffInviteFilter
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'getEventInvitePage',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.public.lists.getEventInvitePage,
      data
    )

    return response?.data?.data
  },

  updateUser: async (args: IUpdateStaffUserArgs): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'updateAdmUserEvent',
      ...camelCaseToSnakeCase(args),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.updateAdmUserEvent,
      data
    )
    return response?.data
  },
  deleteUser: async (args: IDeleteStaffUserArgs): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'deleteAdmUserEvent',
      ...camelCaseToSnakeCase(args),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.deleteAdmUserEvent,
      data
    )
    return response?.data
  },
}
