import { useState } from './useState'

import { IProps } from './types'

const FieldAddressState = ({ field, formik, ...props }: IProps) => {
  const { states, isLoading } = useState()

  return (
    <>
      <div className='fv-row mb-10'>
        {/*begin::Label*/}
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className={field.mandatory === '1' ? 'required' : ''}>
            Estado
          </span>
          <span
            className='ms-1'
            data-bs-toggle='tooltip'
            title={`Digite o(a) ${field.register_field_name}`}
          >
            {field.mandatory === '1' && (
              <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
              </i>
            )}
          </span>
        </label>
        {/*end::Label*/}
        {/*begin::Input*/}

        <select
          className={`form-select form-select-sm form-select-solid ${
            formik.touched.stateId && formik.errors.stateId ? 'is-invalid' : ''
          }`}
          data-control='select2'
          data-placeholder='Selecione o estado'
          data-hide-search='true'
          defaultValue=''
          {...props}
        >
          <option value='' disabled>
            {isLoading ? 'Carregando estados' : 'Selecionar o estado'}
          </option>
          {states?.map((state, index) => (
            <option
              key={`${index}-${state.uf_codigo}`}
              value={state.uf_codigo}
              selected={state.uf_codigo === formik.values.stateId}
            >
              {state.uf_descricao}
            </option>
          ))}
        </select>

        {formik.touched.stateId && formik.errors.stateId && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.stateId}</div>
          </div>
        )}
        {/*end::Input*/}
      </div>
    </>
  )
}

export default FieldAddressState
