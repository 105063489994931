export const { REACT_APP_SITE_URL: SITE_URL } = process.env
export const FLYER_URL = 'https://static.bkstage.online/events'

export const PRIVATE_GENERIC_ROUTE = '/*'

export const BUILDER_ROUTE = '/builder'
export const MENU_TEST_ROUTE = '/menu-test'
export const MEGA_MENU_TEST_ROUTE = '/mega-menu'

export const GENERIC_400_ROUTE = '404'
export const GENERIC_500_ROUTE = '500'

export const GENERIC_LOGIN_ROUTE = 'login'
export const GENERIC_REGISTRATION_ROUTE = 'registration'
export const GENERIC_FORGOT_PASSWORD_ROUTE = 'forgot-password'
export const GENERIC_TWO_FACTOR_ROUTE = 'two-factor'
export const GENERIC_NEW_PASSWORD_ROUTE = 'new-password/:token'

export const GENERIC_LISTS_ROUTE = 'lists'
export const GENERIC_LIST_ROUTE = 'list'
export const GENERIC_NEW_ROUTE = 'new'
export const GENERIC_EDIT_ROUTE = 'edit'
export const GENERIC_HORIZONTAL_ROUTE = 'horizontal'
export const GENERIC_VERTICAL_ROUTE = 'vertical'
export const GENERIC_CHARTS_ROUTE = 'charts'
export const GENERIC_FEEDS_ROUTE = 'feeds'
export const GENERIC_MIXED_ROUTE = 'mixed'
export const GENERIC_TABLES_ROUTE = 'tables'
export const GENERIC_STATISTICS_ROUTE = 'statistics'
export const GENERIC_OVERVIEW_ROUTE = 'overview'
export const GENERIC_SETTINGS_ROUTE = 'settings'
export const GENERIC_PROJECTS_ROUTE = 'projects'
export const GENERIC_CAMPAIGNS_ROUTE = 'campaigns'
export const GENERIC_DOCUMENTS_ROUTE = 'documents'
export const GENERIC_CONNECTIONS_ROUTE = 'connections'
export const GENERIC_USERS_ROUTE = 'users'

export const GENERIC_PRIVATE_CHAT_ROUTE = 'private-chat'
export const GENERIC_GROUP_CHAT_ROUTE = 'group-chat'
export const GENERIC_DRAWER_CHAT_ROUTE = 'drawer-chat'

export const ERROR_ROUTE = '/error'
export const ERROR_GENERIC_ROUTE = '/error/*'
export const ERROR_404_ROUTE = '/error/404'
export const ERROR_500_ROUTE = '/error/500'

export const AUTH_ROUTE = '/auth'
export const AUTH_GENERIC_ROUTE = '/auth/*'
export const LOGIN_ROUTE = '/auth/login'
export const REGISTRATION_ROUTE = '/auth/registration'
export const FORGOT_PASSWORD_ROUTE = '/auth/forgot-password'
export const TWO_FACTOR_ROUTE = '/auth/two-factor'
export const NEW_PASSWORD_ROUTE = '/auth/new-password/'

export const LOGOUT_ROUTE = '/logout'

export const APPS_ROUTE = '/apps'

export const USER_MANAGMENT_GENERIC_ROUTE = '/apps/user-management/*'
export const USER_MANAGMENT_USERS_ROUTE = '/apps/user-management/users'

export const DAHSBOARD_ROUTE = '/dashboard'
export const DAHSBOARD_BY_EVENT_ID_ROUTE = '/event/:eventId/dashboard'

export const PROMOTERS_GENERIC_ROUTE = 'promoters/*'
export const PROMOTERS_LIST_ROUTE = '/promoters/list'
export const PROMOTERS_NEW_ROUTE = '/promoters/new'

export const LISTS_GENERIC_ROUTE = '/lists/*'
export const LISTS_ROUTE = '/lists'
export const LISTS_LIST_ROUTE = '/lists/list'
export const LISTS_NEW_ROUTE = '/lists/new'

export const EVENTS_ROUTE = '/events'
export const EVENTS_GENERIC_ROUTE = 'events/*'
export const EVENTS_LIST_ROUTE = '/events/list'
export const EVENTS_NEW_ROUTE = '/events/new'

export const EVENT_BY_ID_LISTS_ROUTE = '/event/:eventId/lists'

export const EVENT_GENERIC_ROUTE = '/event-single/*'
export const EVENT_BY_ID_GENERIC_ROUTE = 'event/:eventId/*'
export const EVENT_BY_ID_EDIT_ROUTE = '/event/:eventId/edit'
export const EVENT_BY_ID_ADD_GUESTS_ROUTE = '/event/:eventId/add-guests'
export const EVENT_CHECKIN_ROUTE = '/event-single/:eventId/checkin'

export const STAFF_ROUTE = '/staff'
export const EVENT_BY_ID_STAFF_ROUTE = '/event/:eventId/staff'
export const STAFF_INVITE_ROUTE = '/event-single/add-staff'
export const ADD_STAFF_ROUTE = '/add-staff'

export const GUESTS_ROUTE = '/guests'
export const EVENT_BY_ID_GUESTS_ROUTE = '/event/:eventId/guests'
export const ADD_GUESTS_ROUTE = '/add-guests'
export const LIST_OPEN_ADD_GUEST_ROUTE = '/event-single/add-guests'

export const CHECKIN_ROUTE = '/:eventId/checkin'
export const CHECKIN_BY_EVENT_ID_ROUTE = '/event-single/:eventId/checkin'

export const CHAT_ROUTE = '/apps/chat'
export const CHAT_GENERIC_ROUTE = '/apps/chat/*'
export const CHAT_PRIVATE_ROUTE = '/apps/chat/private-chat'
export const CHAT_GROUP_ROUTE = '/apps/chat/group-chat'
export const CHAT_DRAWER_ROUTE = '/apps/chat/drawer-chat'

export const CRAFTED_ROUTE = '/crafted'
export const CRAFTED_PAGES_ROUTE = '/crafted/pages'

export const CRAFTED_ACCOUNTS_GENERIC_ROUTE = '/crafted/accounts'

export const CRAFTED_ACCOUNT_GENERIC_ROUTE = '/crafted/account/*'
export const CRAFTED_ACCOUNT_OVERVIEW_ROUTE = '/crafted/account/overview'
export const CRAFTED_ACCOUNT_SETTINGS_ROUTE = '/crafted/account/settings'

export const CRAFTED_PROFILE_ROUTE = '/crafted/pages/profile'
export const CRAFTED_PROFILE_GENERIC_ROUTE = '/crafted/pages/profile/*'
export const CRAFTED_PROFILE_OVERVIEW_ROUTE = '/crafted/pages/profile/overview'
export const CRAFTED_PROFILE_ACCOUNT_ROUTE = '/crafted/pages/profile/account'
export const CRAFTED_PROFILE_SETTINGS_ROUTE = '/crafted/pages/profile/settings'
export const CRAFTED_PROFILE_PROJECTS_ROUTE = '/crafted/pages/profile/projects'
export const CRAFTED_PROFILE_CAMPAIGNS_ROUTE =
  '/crafted/pages/profile/campaigns'
export const CRAFTED_PROFILE_DOCUMENTS_ROUTE =
  '/crafted/pages/profile/documents'
export const CRAFTED_PROFILE_CONNECTIONS_ROUTE =
  '/crafted/pages/profile/connections'

export const CRAFTED_WIDGETS_ROUTE = '/crafted/widgets'
export const CRAFTED_WIDGETS_GENERIC_ROUTE = '/crafted/widgets/*'
export const CRAFTED_WIDGETS_LISTS_ROUTE = '/crafted/widgets/lists'
export const CRAFTED_WIDGETS_CHARTS_ROUTE = '/crafted/widgets/charts'
export const CRAFTED_WIDGETS_STATISTICS_ROUTE = '/crafted/widgets/statistics'
export const CRAFTED_WIDGETS_MIXED_ROUTE = '/crafted/widgets/mixed'
export const CRAFTED_WIDGETS_TABLES_ROUTE = '/crafted/widgets/tables'
export const CRAFTED_WIDGETS_FEEDS_ROUTE = '/crafted/widgets/feeds'

export const CRAFTED_WIZARDS_ROUTE = '/crafted/pages/wizards'
export const CRAFTED_WIZARDS_GENERIC_ROUTE = '/crafted/pages/wizards/*'
export const CRAFTED_WIZARDS_GENERIC_HORIZONTAL_ROUTE =
  '/crafted/pages/wizards/horizontal'
export const CRAFTED_WIZARDS_GENERIC_VERTICAL_ROUTE =
  '/crafted/pages/wizards/vertical'
