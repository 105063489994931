import clsx from 'clsx'
import { Link } from 'react-router-dom'

import ModalTwoFactor from 'src/components/ModalTwoFactor'
import { useRegistration } from './useRegistration'
import { LOGIN_ROUTE } from 'src/constants/routes'

export function Registration() {
  const {
    formik,
    isLoading,
    displayModal,
    handleModalTwoFactore,
    codeEmailInvalid,
    onChangeInputCode,
  } = useRegistration()

  const textInstructionModal = `Informe o código de verificação enviado 
	para o e-mail que você forneceu: ${formik.values.email}\n\n`

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Cadastre-se</h1>
          {/* end::Title */}

          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group fullname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Usuário</label>
          <input
            placeholder='Usuário'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('slug')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.slug && formik.errors.slug,
              },
              {
                'is-valid': formik.touched.slug && !formik.errors.slug,
              }
            )}
          />
          {formik.touched.slug && formik.errors.slug && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.slug}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group fullname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Nome Completo
          </label>
          <input
            placeholder='Nome Completo'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('fullname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.fullname && formik.errors.fullname,
              },
              {
                'is-valid': formik.touched.fullname && !formik.errors.fullname,
              }
            )}
          />
          {formik.touched.fullname && formik.errors.fullname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.fullname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group birthdate */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Data de Nascimento
          </label>
          <input
            placeholder='Data de Nascimento'
            type='date'
            autoComplete='off'
            data-inputmask-alias='datetime'
            data-inputmask-inputformat='dd/mm/yyyy'
            maxLength={4}
            {...formik.getFieldProps('birthdate')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid':
                  formik.touched.birthdate && formik.errors.birthdate,
              },
              {
                'is-valid':
                  formik.touched.birthdate && !formik.errors.birthdate,
              }
            )}
          />
          {formik.touched.birthdate && formik.errors.birthdate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.birthdate}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group phone */}
        <div className='form-group fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Telefone
          </label>
          <input
            placeholder='Telefone'
            type='tel'
            autoComplete='off'
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.phone && formik.errors.phone,
              },
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Senha'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid':
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 ou mais caracteres, com o mix de letra maiúscula, número e
            símbolo.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Confirme a Senha
          </label>
          <input
            type='password'
            placeholder='Senha'
            autoComplete='off'
            {...formik.getFieldProps('changePassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid':
                  formik.touched.changePassword && formik.errors.changePassword,
              },
              {
                'is-valid':
                  formik.touched.changePassword &&
                  !formik.errors.changePassword,
              }
            )}
          />
          {formik.touched.changePassword && formik.errors.changePassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changePassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-2'>
          <label
            className='form-check form-check-inline'
            htmlFor='kt_login_toc_agree'
          >
            <input
              className={`form-check-input ${
                formik.touched.acceptTerms && formik.errors.acceptTerms
                  ? 'is-invalid'
                  : ''
              }`}
              type='checkbox'
              id='kt_login_toc_agree'
              checked={formik.values.acceptTerms === 'Y'}
              onChange={(event) => {
                formik.setFieldValue(
                  'acceptTerms',
                  event.target.checked ? 'Y' : 'N'
                )
              }}
            />
            <span>
              Eu aceito os{' '}
              <a
                href='/politica-de-privacidade-termos-de-uso'
                target='_blank'
                className='ms-1 link-primary'
              >
                termos e condições de uso de Backstage.{' '}
              </a>
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label
            className='form-check form-check-inline'
            htmlFor='kt_login_marketing_agree'
          >
            <input
              className={`form-check-input ${
                formik.touched.receiveOffers && formik.errors.receiveOffers
                  ? 'is-invalid'
                  : ''
              }`}
              type='checkbox'
              id='kt_login_marketing_agree'
              checked={formik.values.receiveOffers === 'Y'}
              onChange={(event) => {
                formik.setFieldValue(
                  'receiveOffers',
                  event.target.checked ? 'Y' : 'N'
                )
              }}
            />
            <span>Eu aceito receber comunicações de Backstage</span>
          </label>
          {formik.touched.receiveOffers && formik.errors.receiveOffers && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.receiveOffers}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Campos Extras */}

        {/* slug é preciso quando usuário é uma empresa produtora de eventos */}
        {/* event_level_hash é preciso quando usuário é uma pessoa se cadastrando para promover o evento */}
        {/* captcha é preciso em todos os submits */}
        {/* action_pt é pt1 = validacao dos campos e envio do email | pt2 = verifica o codigo do email e salva */}
        {/* email_code é Código recebido por email para validar o cadastro */}
        {/* captcha é preciso em todos os submits */}

        {/* end::Campos Extras */}

        {/* begin::Form group */}
        <div className='text-center'>
          {/* <Link to={TWO_FACTOR_ROUTE}> */}
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={
              isLoading || !formik.isValid || formik.values.acceptTerms === 'N'
            }
          >
            {!isLoading && <span className='indicator-label'>Cadastrar</span>}
            {isLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Por favor aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {/* </Link> */}
          <Link to={LOGIN_ROUTE}>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light w-100 mb-5'
            >
              Cancelar
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>

      <ModalTwoFactor
        // formik={formik}
        displayModal={displayModal}
        handleModalTwoFactore={handleModalTwoFactore}
        codeEmailInvalid={codeEmailInvalid}
        isLoading={isLoading}
        handleSubmit={formik.handleSubmit}
        disabled={formik.values.emailCode.length < 6 || isLoading}
        onChangeInputCode={onChangeInputCode}
        value={formik.values.emailCode}
        name={'emailCode'}
        instructionText={textInstructionModal}
      />
    </>
  )
}
