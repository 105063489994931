import { IServiceResponse } from '../../../resources/types/services'
import { toast } from 'react-toastify'

export const getErrorMessage = (response: IServiceResponse) => {
  if (!response.errors) return response.msg

  const errorsList = Object.keys(response.errors)
    .map(function (key) {
      return response.errors[key]
    })
    .join(' ')
  let formattedMessage = errorsList
    ? `${response.msg}. (Mais detalhes: ${errorsList})`
    : response.msg

  return formattedMessage
}

export const showErrorMessage = (response: IServiceResponse) => {
  const formattedMessage = getErrorMessage(response)
  toast.error(formattedMessage)
}

//TODO: Melhorar tipagem
export const handleAPIReponse = (response: any): any => {
  if (response.success === 0) {
    showErrorMessage(response)
    return
  }

  return response.data
}
