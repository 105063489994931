import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  // useRef,
  useMemo,
  useCallback,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel } from './_models'
import * as authHelper from './AuthHelpers'
// import { getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { IAuthUser } from '@resources/types/services/auth'
import { AuthContextProps } from '@resources/types/modules/auth'
import { USER_LOCAL_STORAGE_KEY } from 'src/constants/auth'

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  getFirstname: () => '',
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IAuthUser | undefined>()

  const saveAuth = useCallback((auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }, [])

  const logout = useCallback(() => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }, [saveAuth])

  const getFirstname = useCallback(() => {
    if (!currentUser?.fullname) return ''

    const firstName = currentUser.fullname.split(' ')
    // console.log(firstName[0])
    return firstName[0]
  }, [currentUser])

  const contextProps = useMemo(
    () => ({
      auth,
      saveAuth,
      currentUser,
      setCurrentUser,
      logout,
      getFirstname,
    }),
    [auth, saveAuth, currentUser, setCurrentUser, logout, getFirstname]
  )

  useEffect(() => {
    saveAuth(auth)
  }, [auth, saveAuth])

  return (
    <AuthContext.Provider value={contextProps}>{children}</AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, currentUser, setCurrentUser } = useAuth()

  const token = localStorage.getItem(authHelper.AUTH_LOCAL_STORAGE_KEY)
  // const didRequest = useRef(false)

  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    // const requestUser = async (apiToken: string) => {
    //   try {
    //     if (!didRequest.current) {
    //       const { data } = await getUserByToken(apiToken)
    //       if (data) {
    //         setCurrentUser(data)
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //     if (!didRequest.current) {
    //       logout()
    //     }
    //   } finally {
    //     setShowSplashScreen(false)
    //   }

    //   return () => (didRequest.current = true)
    // }

    if (auth?.api_token) {
      // requestUser(auth.api_token).catch(() => console.error)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [logout, auth?.api_token, setCurrentUser])

  useEffect(() => {
    if (!token) {
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
    }
  }, [token])

  useEffect(() => {
    const userLocalStorage: string | null =
      localStorage.getItem(USER_LOCAL_STORAGE_KEY) || null
    const formattedUserLocalStorage = userLocalStorage
      ? JSON.parse(userLocalStorage)
      : null

    if (currentUser === formattedUserLocalStorage) return

    if (currentUser) {
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(currentUser))
      return
    }

    if (formattedUserLocalStorage) {
      setCurrentUser(formattedUserLocalStorage)
    }
  }, [currentUser, setCurrentUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
