/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../helpers'
import { toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string
}

const LatestEvents: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Últimos Eventos</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Eventos dos últimos 30 dias
          </span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_tab_1'
              >
                Em andamento
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_tab_2'
              >
                Finalizados
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5 py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle table-row-dashed gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-80px'>Flyer</th>
                    <th className='min-w-200px'>Nome do Evento</th>
                    <th className='min-w-200px'>Local e Data</th>
                    <th className='min-w-50px'>Status</th>
                    <th className='min-w-50px text-end'>Ação</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-primary fw-bolder me-auto px-4 py-3'>
                        EM ANDAMENTO
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-primary fw-bolder me-auto px-4 py-3'>
                        EM ANDAMENTO
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-primary fw-bolder me-auto px-4 py-3'>
                        EM ANDAMENTO
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-primary fw-bolder me-auto px-4 py-3'>
                        EM ANDAMENTO
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-primary fw-bolder me-auto px-4 py-3'>
                        EM ANDAMENTO
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle table-row-dashed gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-80px'>Flyer</th>
                    <th className='min-w-200px'>Nome do Evento</th>
                    <th className='min-w-200px'>Local e Data</th>
                    <th className='min-w-50px'>Status</th>
                    <th className='min-w-50px text-end'>Ação</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-success fw-bolder me-auto px-4 py-3'>
                        FINALIZADOS
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-success fw-bolder me-auto px-4 py-3'>
                        FINALIZADOS
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-success fw-bolder me-auto px-4 py-3'>
                        FINALIZADOS
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-success fw-bolder me-auto px-4 py-3'>
                        FINALIZADOS
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label event-image'>
                          <img
                            src={toAbsoluteUrl('/media/events/flyer.png')}
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Pagode da Paz
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        Agência TNA Produções
                      </span>
                    </td>
                    <td className=''>
                      <span className='text-dark fw-bold d-block fs-7'>
                        Garden Mandarin
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        07/05/2023 às 16h
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-success fw-bolder me-auto px-4 py-3'>
                        FINALIZADOS
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='/events/1'
                        title=''
                        className='btn btn-icon btn-sm btn-light btn-active-primary w-25px h-25px'
                      >
                        <i className='ki-duotone ki-black-right fs-2'></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_8_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-warning'>
                          <KTIcon
                            iconName='category'
                            className='fs-2x text-warning'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Maths Championship
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        By Tom Gere
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>
                        10:05PM, 25 Oct
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        Date
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-info'>
                          <KTIcon
                            iconName='compass'
                            className='fs-2x text-info'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Who Knows Geography
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        By Zoey Dylan
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>
                        3:22PM, 07 Sep
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        Date
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-primary'>
                          <KTIcon
                            iconName='abstract-26'
                            className='fs-2x text-primary'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        Napoleon Days
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        By Luke Owen
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>
                        1:20PM, 02 Dec
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        Date
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label bg-light-danger'>
                          <KTIcon
                            iconName='scroll'
                            className='fs-2x text-danger'
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                      >
                        School Music Festival
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>
                        by Rose Liam
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>
                        4:20PM, 03 Sep
                      </span>
                      <span className='text-muted fw-semibold d-block fs-8'>
                        Date
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <i className='bi bi-three-dots fs-5'></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { LatestEvents }
