import { IUseEventSinglePromoters } from 'src/resources/types/hooks/staff'
import { IEventInfoData } from 'src/resources/types/services/events'
import { IStaffEventLevelUsers } from 'src/resources/types/services/staff'
import { Dispatch, SetStateAction } from 'react'
import { IntlShape } from 'react-intl'

export enum UserAccessLevel {
  producer = 'producer',
  manager = 'manager',
  promoter = 'promoter',
  hostess = 'hostess',
}

export enum AdmLevelId {
  producer = 2,
  manager = 3,
  promoter = 4,
  hostess = 6,
}

export type AdmLevel = 'producer' | 'manager' | 'promoter' | 'hostess'

export interface IEventsSinglePromotersPage extends IUseEventSinglePromoters {
  activeTabId: AdmLevelId
  eventLevelHash: string
  setActiveTabId: Dispatch<SetStateAction<AdmLevelId>>
  intl: IntlShape
}

export interface IGetEventLevelHash {
  admLevelId: number
  eventInfoData: IEventInfoData
}

export interface IGetEventStaffInviteLink {
  accountSlug: string
  eventLevelHash: string
  accessLevelId: AdmLevelId
  intl: IntlShape
}

export interface IEditUserAccessLevel {
  current: AdmLevel
  selected: AdmLevel
}

export interface IEventLevelIds {
  producer: number | null
  manager: number | null
  promoter: number | null
  hostess: number | null
}

export interface IEventsSingleStaffManagmentTab {
  users: IStaffEventLevelUsers[] | []
  tabId: string
  userAdmLevel: AdmLevel
  eventLevelId: AdmLevelId
}

export interface IEventsSingleStaffUserAccessLevels {
  manager: string
  promoter: string
  hostess: string
}

export interface IOpenEditPromoterModal {
  username: string
  userEmail: string
  userAccountId: number
  userAccessLevel: UserAccessLevel
}

export interface IOpenDeletePromoterModal {
  username: string
  userAccountId: number
}

export interface IEventsSingleAddStaffPage {
  intl: IntlShape
}

export interface IEventsSingleAddStaffPageUrlParams {
  slug: string // accountSlug
  level: string // eventLevelHash
}
