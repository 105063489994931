import { AxiosResponse } from 'axios'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'
import {
  IEventInfoData,
  IEventInfoFilter,
  IEventInfoDataParamsCamelCase,
  IEventListResponse,
  IEventValidateCouponFilter,
} from 'src/resources/types/services/events'
import { camelCaseToSnakeCase } from 'src/resources/utils/camelCase'

import {
  IEventsListFilter,
  IEventFieldList,
  IEventUploadBannerResponse,
} from 'src/resources/types/services/events'
import { IServiceResponse } from '../../resources/types/services'

export const EventService = {
  list: async (filter: IEventsListFilter): Promise<IEventListResponse> => {
    const api = getApiInstance()

    const data = {
      action: 'getEvents',
      ...camelCaseToSnakeCase(filter),
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.getEvents,
      data
    )

    return response?.data
  },
  save: async (data: any): Promise<IEventListResponse[]> => {
    const api = getApiInstance()

    const registerFieldsInArray: any = []

    data.registerFields.forEach((field: any) => {
      registerFieldsInArray[`register_fields[${field.id}]`] = field.value
    })

    delete data.registerFields

    const dataToSnakeCase = {
      action: 'createEvent',
      ...camelCaseToSnakeCase(data),
      ...registerFieldsInArray,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.createEvent,
      dataToSnakeCase
    )

    return response?.data
  },
  getFieldList: async (event_id = 0): Promise<IEventFieldList[]> => {
    const api = getApiInstance()

    const data: any = {
      action: 'getRegisterFields',
    }

    if (event_id) data.event_id = event_id

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.getRegisterFiels,
      data
    )

    return response?.data?.data
  },
  uploadBanner: async (file: any): Promise<IEventUploadBannerResponse> => {
    const api = getApiInstance()
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    const data = {
      action: 'uploadImageTmp',
      qqfile: file,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.uploadEventImage,
      data,
      config
    )

    return response.data
  },
  getEventInfo: async (filter: IEventInfoFilter): Promise<IEventInfoData> => {
    const api = getApiInstance()
    const data = {
      action: 'getEvent',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.getEvent,
      data
    )
    return response?.data
  },
  updateEventInfo: async (
    params: IEventInfoDataParamsCamelCase
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()

    const registerFieldsInArray: any = []

    params?.registerFields?.forEach((field: any) => {
      if (!field?.id) return
      registerFieldsInArray[`register_fields[${field.id}]`] = field.value
    })

    delete params.registerFields

    const data = {
      action: 'updateEvent',
      ...camelCaseToSnakeCase(params),
      ...registerFieldsInArray,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.updateEvent,
      data
    )
    return response?.data
  },
  getListPageBySlugList: async (slugList: string): Promise<IEventInfoData> => {
    const api = getApiInstance()

    const data = {
      action: 'getListPage',
      list_slug: slugList,
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.public.lists.getListPage,
      data
    )
    return response?.data.data
  },
  validateCoupon: async (
    filter: IEventValidateCouponFilter
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()

    const dataToSnakeCase = {
      action: 'validateCoupon',
      ...camelCaseToSnakeCase(filter),
    }

    delete dataToSnakeCase.success_callback_fn
    delete dataToSnakeCase.error_callback_fn

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.validateCoupon,
      dataToSnakeCase
    )

    return response?.data
  },
}
