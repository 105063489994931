import { useState, useEffect, useCallback } from 'react'
import { useFormik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'

import { useAccountForgotPasswordSendTokenToEmail } from 'src/hooks/account/useAccount'
import { IForgotPasswordSendTokenToEmailResponse } from 'src/resources/types/services/account'

import { initialValues, forgotPasswordSchema } from './form'

export const useForgotPassword = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [msgErrors, setMsgErrors] = useState<Array<string>>([''])

  const { mutate, isLoading, isError, data } =
    useAccountForgotPasswordSendTokenToEmail()

  const onSuccess = (
    response: IForgotPasswordSendTokenToEmailResponse
  ): void => {
    formik.resetForm()

    if (response.errors) {
      const keys: any = Object.keys(response.errors)

      const msgErros: Array<string> = []

      keys.forEach((key: string) => {
        msgErros.push(String(response?.errors?.[key]))
      })

      setMsgErrors(msgErros)
      return
    }
  }

  const onError = (error: any): void => {
    console.error('useForgotPassword: ', error)
    toast.error('Houve um erro inesperado. Por favor, tente novamente!')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      mutate(
        {
          email: values.email,
          captcha: values.captcha,
        },
        {
          onSuccess,
          onError,
        }
      )
    },
  })

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()
    formik.setFieldValue('captcha', token)
  }, [executeRecaptcha, formik])

  useEffect(() => {
    handleReCaptchaVerify().catch((error) => console.error(error))
  }, [handleReCaptchaVerify])

  return {
    formik,
    loading: isLoading,
    isSuccess: !!data?.success,
    hasErrors: isError || data?.errors,
    msgErrors,
  }
}
