import { useEffect } from 'react'

import { useLocationAddressByCep } from 'src/hooks/location/useLocation'

import { IUseAddressProps } from '../types'

export const useNeighborhood = ({ formik }: IUseAddressProps) => {
  const { data } = useLocationAddressByCep()

  useEffect(() => {
    if (!data?.address_data?.neighborhood) return

    formik.setFieldValue('neighborhood', data.address_data.neighborhood)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
