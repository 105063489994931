/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, KeyboardEvent, Fragment } from 'react'
import { useIntl } from 'react-intl'

import { toAbsoluteUrl, KTIcon } from 'src/_metronic/helpers'
import { PageTitle } from 'src/_metronic/layout/core'
import { generateKey } from 'src/resources/utils/generateKey'

// import { IEventsSingleAddGuestsPage } from 'src/resources/types/pages/guests'
import { FLYER_URL } from 'src/constants/routes'
import { useEventSingleAddGuests } from './useEventSingleAddGuests'
import { useLayout } from 'src/_metronic/layout/core'
// import { RegisterFieldHelper } from 'src/resources/helper/registerFields'
import { registerFieldsConstants } from 'src/constants/registerFields'

import FieldCpf from './fields/Cpf'
import FieldPhone from './fields/Phone'
import FieldBirthdate from './fields/Birthdate'
import FieldRg from './fields/Rg'
import FieldCep from './fields/Cep'
import FieldAddress from './fields/Address/Address'
import FieldAddressNumber from './fields/Address/Number'
import FieldAddressNeighborhood from './fields/Address/Neighborhood'
import FieldAddressComplement from './fields/Address/Complement'
import FieldAddressState from './fields/Address/State'
import FieldAddressCity from './fields/Address/City'
import FieldJobCompany from './fields/JobCompany'
import FieldJobPosition from './fields/JobPosition'
import FieldSocialMedia from './fields/SocialMedia'

const EventsSingleAddGuestsPage = () => {
  const { config } = useLayout()

  const {
    isLoading,
    formik,
    closeAddGuestsModal,
    formattedDateAndHour,
    formattedEventCloseRegisterDateAndHour,
    defaultDateAndHourValue,
    openAddGuestsModal,
    handleAddGuestsInputChange,
    removeGuest,

    //variables Event
    eventAddress,
    eventFlyer,
    eventName,
    eventVideo,
    registerFields,
    isLoadingMutate,
  } = useEventSingleAddGuests()

  if (!config.app?.toolbar?.display) {
    return null
  }

  const renderLoading = () => {
    return (
      <div className='d-flex justify-content-center mt-16'>
        <div
          className='spinner-border text-light'
          style={{ width: 80, height: 80 }}
          role='status'
        >
          <span className='visually-hidden'>Carregando...</span>
        </div>
      </div>
    )
  }

  return isLoading ? (
    renderLoading()
  ) : (
    <>
      {/* MODAL ADD GUESTS */}
      <div className='modal_container modal-add-guests'>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_guests'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          {/* begin::Modal dialog */}
          <div className='modal-dialog modal-dialog-centered mw-650px'>
            {/* begin::Modal content */}
            <div className='modal-content'>
              <div className='modal-header'>
                {/* begin::Modal title */}
                <h2 className='fw-bolder mb-0'>Adicionar Nome na Lista</h2>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={closeAddGuestsModal}
                  style={{ cursor: 'pointer' }}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
                {/* end::Close */}
              </div>

              {/* begin::Modal body */}
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='card-body pt-5' id='kt_chat_contacts_body'>
                    {/*begin::Input group*/}
                    <div className='fv-row mb-10'>
                      {/*begin::Label*/}
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Nome Completo</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Digite seu nome completo'
                        >
                          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type='text'
                        className={`form-control form-control-lg form-control-solid ${
                          formik.touched.fullname && formik.errors.fullname
                            ? 'is-invalid'
                            : ''
                        }`}
                        name='fullname'
                        placeholder='Digite seu nome completo'
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.fullname && formik.errors.fullname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.fullname}
                          </div>
                        </div>
                      )}
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}

                    {/*begin::Input group*/}
                    <div className='fv-row mb-10'>
                      {/*begin::Label*/}
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>E-mail</span>
                        <span
                          className='ms-1'
                          data-bs-toggle='tooltip'
                          title='Digite seu email'
                        >
                          <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type='text'
                        className={`form-control form-control-lg form-control-solid ${
                          formik.touched.email &&
                          formik.errors.email &&
                          'is-invalid'
                        }`}
                        name='email'
                        placeholder='Digite seu email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.email}
                          </div>
                        </div>
                      )}
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}

                    {/*begin::Input group*/}
                    {registerFields.map((field) => {
                      const setupRegisterFiedsComponents = {
                        [registerFieldsConstants.cpf]: () => (
                          <FieldCpf
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('cpf')}
                          />
                        ),
                        [registerFieldsConstants.phone]: () => (
                          <FieldPhone
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('phone')}
                          />
                        ),
                        [registerFieldsConstants.birthdate]: () => (
                          <FieldBirthdate
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('birthdate')}
                          />
                        ),
                        [registerFieldsConstants.completeAddress]: () => {
                          const hasCep = registerFields.find(
                            (item) =>
                              item.register_field_id ===
                                registerFieldsConstants.cep &&
                              item.mandatory === '1'
                          )

                          return (
                            <>
                              <FieldAddress
                                formik={formik}
                                field={field}
                                {...formik.getFieldProps('address')}
                                disabled={
                                  hasCep
                                    ? !!formik.errors?.cep ||
                                      !formik.values?.cep
                                    : false
                                }
                              />

                              <FieldAddressNumber
                                formik={formik}
                                field={field}
                                {...formik.getFieldProps('number')}
                              />

                              <FieldAddressNeighborhood
                                formik={formik}
                                field={field}
                                {...formik.getFieldProps('neighborhood')}
                              />

                              <FieldAddressComplement
                                formik={formik}
                                field={field}
                                {...formik.getFieldProps('complement')}
                              />
                            </>
                          )
                        },
                        [registerFieldsConstants.stateAndCity]: () => (
                          <>
                            <FieldAddressState
                              formik={formik}
                              field={field}
                              {...formik.getFieldProps('stateId')}
                            />

                            <FieldAddressCity
                              formik={formik}
                              field={field}
                              {...formik.getFieldProps('cityId')}
                            />
                          </>
                        ),
                        [registerFieldsConstants.cep]: () => (
                          <FieldCep
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('cep')}
                          />
                        ),
                        [registerFieldsConstants.rg]: () => (
                          <FieldRg
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('rg')}
                          />
                        ),
                        [registerFieldsConstants.socialMedia]: () => (
                          <FieldSocialMedia
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('socialMedia')}
                          />
                        ),
                        [registerFieldsConstants.jobPosition]: () => (
                          <FieldJobPosition
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('jobPosition')}
                          />
                        ),
                        [registerFieldsConstants.jobCompany]: () => (
                          <FieldJobCompany
                            formik={formik}
                            field={field}
                            {...formik.getFieldProps('jobCompany')}
                          />
                        ),
                      }

                      try {
                        return setupRegisterFiedsComponents[
                          field.register_field_id
                        ]()
                      } catch {
                        return null
                      }
                    })}
                    {/*end::Input group*/}

                    {/*begin::Input group*/}
                    <div className='fv-row mb-10'>
                      {/*begin::Label*/}
                      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        Amigos
                      </label>
                      {/*end::Label*/}

                      <div className='amigos-container rounded p-5'>
                        <div className='list-amigos'>
                          {formik.values.guests.split(';').map((guest) => {
                            if (guest === '') return <></>

                            return (
                              <div
                                key={generateKey()}
                                className='d-flex flex-stack py-1'
                              >
                                <div className='d-flex align-items-center'>
                                  <div className='ms-0'>
                                    <span className='fs-5 fw-bolder text-gray-900'>
                                      {guest}
                                    </span>
                                  </div>
                                </div>

                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                    title='Deletar'
                                    type='button'
                                    className='btn btn-icon btn-primary btn-bg-danger btn-hover-color-danger-active btn-sm'
                                    onClick={() => removeGuest(guest)}
                                  >
                                    <KTIcon iconName='trash' className='fs-3' />
                                  </button>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        {/*begin::Input*/}
                        <input
                          type='text'
                          className={`form-control form-control-lg form-control-solid pt-3 ${
                            formik.touched.guests &&
                            formik.errors.guests &&
                            'is-invalid'
                          }`}
                          name='guestsNew'
                          placeholder='Digite o nome do amigo e aperte enter'
                          defaultValue=''
                          value={formik.values.guestsNew}
                          onChange={formik.handleChange}
                          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                            handleAddGuestsInputChange(e)
                          }
                        />
                        <input
                          type='hidden'
                          name='guests'
                          value={formik.values.guests}
                        />
                        {formik.touched.guests && formik.errors.guests && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {formik.errors.guests}
                            </div>
                          </div>
                        )}
                        {/*end::Input*/}
                        <small>Digite o nome do amigo e aperte enter</small>
                      </div>
                    </div>
                    {/*end::Input group*/}

                    <div className='d-flex align-items-center justify-content-between fs-5 fw-semibold mb-2'>
                      {/*begin::Input*/}
                      <div className='form-check form-check-solid'>
                        <input
                          className={`form-check-input ${
                            formik.touched.acceptTerms &&
                            formik.errors.acceptTerms &&
                            'is-invalid'
                          }`}
                          type='checkbox'
                          name='acceptTerms'
                          id='lgpd-consent'
                          value={formik.values.acceptTerms}
                          checked={formik.values.acceptTerms === 'Y'}
                          onChange={() => {
                            formik.setFieldValue(
                              'acceptTerms',
                              formik.values.acceptTerms === 'Y' ? 'N' : 'Y'
                            )
                          }}
                        />
                        <label htmlFor='lgpd-consent'>
                          Li e concordo com a{' '}
                          <a href='#'>política de privacidade</a> de Backstage.
                        </label>
                        {formik.touched.acceptTerms &&
                          formik.errors.acceptTerms && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.acceptTerms}
                              </div>
                            </div>
                          )}
                      </div>
                      {/*end::Input*/}
                    </div>

                    <div className='d-flex align-items-center justify-content-between fs-5 fw-semibold mb-2'>
                      {/*begin::Input*/}
                      <div className='form-check form-check-solid'>
                        <input
                          className={`form-check-input ${
                            formik.touched.receiveOffers &&
                            formik.errors.receiveOffers &&
                            'is-invalid'
                          }`}
                          type='checkbox'
                          name='receiveOffers'
                          id='lgpd-mkt-consent'
                          value={formik.values.receiveOffers}
                          checked={formik.values.receiveOffers === 'Y'}
                          onChange={() => {
                            formik.setFieldValue(
                              'receiveOffers',
                              formik.values.receiveOffers === 'Y' ? 'N' : 'Y'
                            )
                          }}
                        />
                        <label htmlFor='lgpd-mkt-consent'>
                          Concordo em receber comunicações de Backstage.
                        </label>
                        {formik.touched.receiveOffers &&
                          formik.errors.receiveOffers && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                {formik.errors.receiveOffers}
                              </div>
                            </div>
                          )}
                      </div>
                      {/*end::Input*/}
                    </div>
                  </div>

                  <div
                    className='card-footer pt-8'
                    id='kt_chat_contacts_footer'
                  >
                    <div className='d-flex flex-stack pt-0'>
                      <div className='mr-2'>
                        <button
                          type='button'
                          className={`btn btn-light-primary me-3`}
                          onClick={closeAddGuestsModal}
                        >
                          <i className='ki-duotone ki-arrow-left fs-4'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                          Cancelar
                        </button>
                      </div>
                      <div>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          onClick={openAddGuestsModal}
                          disabled={isLoadingMutate}
                        >
                          <span className='indicator-label'>
                            Adicionar
                            <i className='ki-duotone ki-plus fs-3 ms-2 me-0'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {/* BODY SEARCH LISTS */}
              </div>
              {/* end::Modal body */}
            </div>
            {/* end::Modal content */}
          </div>
          {/* end::Modal dialog */}
        </div>
        {/* begin::Modal Backdrop */}
        <div
          className='modal-backdrop fade show'
          id='kt_modal_add_guests_bg'
        ></div>
        {/* end::Modal Backdrop */}
      </div>
      {/* MODAL ADD GUESTS */}

      <div className='row g-6 g-xl-9'>
        <div className='col-md-6 col-xl6 m-0'>
          <div className='flyer-container mb-5'>
            <img
              src={`${FLYER_URL}/${eventFlyer}`}
              className='w-100 rounded'
              alt=''
            />
          </div>
        </div>

        <div className='col-md-6 col-xl6 m-0 px-20 d-flex flex-column justify-content-center'>
          {/* begin::Logo */}
          <a href='#' className='mb-10'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-backstage.svg')}
              className='h-35px'
            />
          </a>
          {/* end::Logo */}

          <h1 className='text-primary bs-fs-1'>{eventName}</h1>

          <div className='event-desc text-light mb-5'>
            <strong>Local:</strong> {eventAddress}
            {/* <a href='#'>(Google Maps)</a>{' '} */}
            {formattedDateAndHour !== defaultDateAndHourValue && (
              <>
                <br />
                <strong>Data:</strong> {formattedDateAndHour}
              </>
            )}
          </div>

          <div className='row mb-10'>
            <div className='col-12'>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe
                  className='embed-responsive-item rounded'
                  width='100%'
                  src={eventVideo as string}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={openAddGuestsModal}
              >
                <span className='indicator-label'>
                  Adicionar Nomes na Lista
                </span>
              </button>
              {formattedEventCloseRegisterDateAndHour !==
                defaultDateAndHourValue && (
                <div className='event-desc text-light mt-2'>
                  * Inclusão de nomes na lista até{' '}
                  <strong>{formattedEventCloseRegisterDateAndHour}</strong>
                </div>
              )}
            </div>
          </div>

          {/* 
					<div className="card border border-2">
	  					a
					</div> 
					*/}
        </div>
      </div>
    </>
  )
}

const EventSingleAddGuests: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.EVENTS' })}
      </PageTitle>
      <EventsSingleAddGuestsPage />
    </>
  )
}

export { EventSingleAddGuests }
