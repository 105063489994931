import { useEffect, useCallback } from 'react'
import { useFormik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'
import { useParams, useNavigate } from 'react-router-dom'

import { PasswordMeterComponent } from '../../../../../_metronic/assets/ts/components'

import { useAccountForgotPasswordUpdateByToken } from 'src/hooks/account/useAccount'
import { IForgotPasswordUpdateByTokenResponse } from 'src/resources/types/services/account'
import { LOGIN_ROUTE } from 'src/constants/routes'

import { initialValues, changePasswordSchema } from './form'

export const useNewPassword = () => {
  const navigate = useNavigate()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { token } = useParams()

  const { mutate } = useAccountForgotPasswordUpdateByToken()

  const onSuccess = (response: IForgotPasswordUpdateByTokenResponse): void => {
    if (response.errors) {
      const keys: any = Object.keys(response.errors)

      keys.forEach((key: string) => {
        toast.error(response?.errors?.[key])
      })

      return
    }

    toast.success(response.msg)

    navigate(LOGIN_ROUTE)
  }

  const onError = (error: any): void => {
    console.error('useForgotPassword: ', error)
    toast.error('Houve um erro inesperado. Por favor, tente novamente!')
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      mutate(
        {
          password: values.password,
          password2: values.changePassword,
          token: values.token,
          captcha: values.captcha,
        },
        {
          onSuccess,
          onError,
        }
      )
    },
  })

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()
    formik.setFieldValue('captcha', token)
  }, [executeRecaptcha, formik])

  useEffect(() => {
    handleReCaptchaVerify().catch((error) => {
      if (!error) return
      console.error('handleReCaptchaVerify', error)
    })
  }, [handleReCaptchaVerify])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    formik.setFieldValue('token', token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return {
    formik,
  }
}
