import { AxiosResponse } from 'axios'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'
import {
  IAuthLoginRequest,
  IAuthUser,
  IAuthLoginErrorResponse,
} from 'src/resources/types/services/auth'
import * as authHelper from 'src/app/modules/auth/core/AuthHelpers'

export const AuthService = {
  login: async ({
    email,
    password,
    captcha,
    event_level_hash,
  }: IAuthLoginRequest): Promise<IAuthUser | IAuthLoginErrorResponse> => {
    const api = getApiInstance()

    let payload: any = {
      action: 'loginUser',
      email,
      password,
      captcha: captcha ?? 'Teste12#',
      // list_slug: 'bb3b4fb667a5ddacee7a631232548553',
    }

    if (event_level_hash) {
      payload.event_level_hash = event_level_hash
    }

    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.account.login,
      payload
    )

    if (response?.data?.success) {
      authHelper.setAuth(response.data.access_token)
    }

    return response?.data?.data
  },
}
