import { useEffect, useState } from 'react'

import { useLocationGetAddressByCep } from 'src/hooks/location/useLocation'

import { IUseCepProps } from './types'

export const useCep = ({ formik }: IUseCepProps) => {
  const [cep, setCep] = useState<number>(0)

  const { isLoading } = useLocationGetAddressByCep(cep)
  // const { data: locationAddressByCep } = useLocationAddressByCep()

  useEffect(() => {
    if (
      !formik.touched.cep &&
      formik.errors.cep &&
      formik.values?.cep?.includes('_')
    )
      return

    const cep = formik.values?.cep?.replace('-', '')

    setCep(cep)
  }, [formik.touched.cep, formik.errors.cep, formik.values.cep])

  return { isLoading }
}
