const { REACT_APP_API_URL: API_URL } = process.env

export const ApiGateway = () => {
  const api = {
    url: API_URL,
  }

  return {
    baseURL: `${api.url}/`,
    mode: 'no-cors',
    withCredentials: false,
    credentials: 'same-origin',
    crossdomain: true,
    headers: {
      Accept: '*/*',
      'Accept-Language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    timeout: 60000,
  }
}
