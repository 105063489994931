import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { LOGIN_ROUTE } from 'src/constants/routes'

import { useNewPassword } from './useNewPassword'

export function NewPassword() {
  const { formik } = useNewPassword()

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Nova Senha</h1>
        {/* end::Title */}

        <div className='text-gray-500 w-75 m-auto fw-semibold fs-6'>
          Defina sua nova senha nos campos abaixo:
        </div>
      </div>

      <div className='mb-10 w-75 m-auto '>
        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Senha'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid':
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 ou mais caracteres, com o mix de letra maiúscula, número e
            símbolo.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Confirme a Senha
          </label>
          <input
            type='password'
            placeholder='Senha'
            autoComplete='off'
            {...formik.getFieldProps('changePassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid':
                  formik.touched.changePassword && formik.errors.changePassword,
              },
              {
                'is-valid':
                  formik.touched.changePassword &&
                  !formik.errors.changePassword,
              }
            )}
          />
          {formik.touched.changePassword && formik.errors.changePassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changePassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap flex-column justify-content-center align-items-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary w-75'
          disabled={
            !formik.touched.password ||
            !formik.touched.changePassword ||
            !!formik.errors.password ||
            !!formik.errors.changePassword
          }
        >
          <span className='indicator-label'>Enviar</span>
        </button>

        <div className='text-gray-500 text-center mt-5 w-75 m-auto fw-semibold fs-6'>
          Já trocou sua senha?{' '}
          <Link to={LOGIN_ROUTE} className='w-75'>
            Logar
          </Link>
          .
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}
