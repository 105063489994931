import { useQuery, UseQueryResult, useQueryClient } from 'react-query'

import { LocationService } from 'src/services/location'

import {
  IAddressByCepResponse,
  ILocationStatesResponse,
  ILocationCitiesResponse,
} from 'src/resources/types/services/location'

export const useLocationGetAddressByCep = (
  cep = 0
): UseQueryResult<IAddressByCepResponse> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['locationGetAddressByCep'],
    async () => {
      const response = await LocationService.getAddressByCep(cep)

      queryClient.setQueryData(['locationAddressByCep'], response)

      return response
    },
    {
      enabled: !!cep,
    }
  )
}

export const useLocationAddressByCep =
  (): UseQueryResult<IAddressByCepResponse> => {
    return useQuery(['locationAddressByCep'])
  }

export const useLocationGetStates =
  (): UseQueryResult<ILocationStatesResponse> => {
    return useQuery(['locationGetStates'], () => {
      return LocationService.getStates()
    })
  }

export const useLocationGetCitiesByState = (
  stateId = 0
): UseQueryResult<ILocationCitiesResponse> => {
  return useQuery(
    ['locationGetCitiesByState'],
    () => {
      return LocationService.getCitiesByState(stateId)
    },
    {
      enabled: !!stateId,
      cacheTime: 0,
    }
  )
}
