import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query'

import { StaffService } from 'src/services/staff'
import {
  IDeleteStaffUserArgs,
  IEventStaffInviteData,
  IEventStaffInviteFilter,
  IUpdateStaffUserArgs,
} from 'src/resources/types/services/staff'
import { toast } from 'react-toastify'
import { IServiceResponse } from 'src/resources/types/services'
import { showErrorMessage } from 'src/resources/utils/response'

export const useGetStaffInvitePage = (
  eventStaffInviteFilter: IEventStaffInviteFilter
): UseQueryResult<IEventStaffInviteData> => {
  return useQuery(
    ['getEventList'],
    () => {
      return StaffService.getEventStaffInvitePage(eventStaffInviteFilter)
    },
    {
      /**
       * Para garantir dados mais atualizados refaz a requisição:
       * toda vez que o usuário entra na aba do navegador que mostra os dados
       * e de 1 em 1 minuto
       * */
      refetchOnWindowFocus: true,
      refetchInterval: 5 * (60 * 1000), // 60000ms = 5 minutos
    }
  )
}

export const useStaffUpdateUser = (
  args: IUpdateStaffUserArgs
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  const { refetchFn } = args

  return useMutation({
    mutationFn: (args) => StaffService.updateUser(args),
    onSuccess: (response) => {
      if (response.success === 0) {
        showErrorMessage(response)

        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useStaffDeleteUser = (
  args: IDeleteStaffUserArgs
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  const { refetchFn } = args

  return useMutation({
    mutationFn: (args) => StaffService.deleteUser(args),
    onSuccess: (response) => {
      if (response.success === 0) {
        showErrorMessage(response)

        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}
