import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useFormik } from 'formik'

import { useAddGuestAsPublicUser } from 'src/hooks/guests/useGuests'
import { IAddGuestAsPublicUserFilter } from 'src/resources/types/services/guests'
import { IEventsSingleAddGuestsPageUrlParams } from 'src/resources/types/pages/lists'
import { getCurrentUrlParams } from 'src/resources/utils/url'
import {
  formatAsDDMMYYYYHHhmm,
  formatAsddddDDMMYYYYHHhmm,
} from 'src/resources/utils/dateAndTime'
import { useEventGetListPageBySlugList } from 'src/hooks/events/useEvents'
import { formatYoutubeUrl } from 'src/resources/utils/url'
import { registerFieldsConstants } from 'src/constants/registerFields'
import { initialValues, getPublicAddGuestsSchema } from './form'
import { IPropsSchema } from './form/types'

export const useEventSingleAddGuests = () => {
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const urlParams: IEventsSingleAddGuestsPageUrlParams = getCurrentUrlParams([
    {
      name: 'listSlug',
      defaultValue: '',
    },
    {
      name: 'admUserId',
      defaultValue: null,
    },
  ]) as IEventsSingleAddGuestsPageUrlParams

  const { listSlug, admUserId } = urlParams

  const { data: dataEventInfo, isLoading } =
    useEventGetListPageBySlugList(listSlug)

  const closeAddGuestsModal = () => {
    document.body.classList.remove('modal-open')
    document.body.classList.remove('modal-add-guests')
    // document.body.classList.remove('modal-promoter-edit')
  }

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate: addGuestAsPublicUserMutate, isLoading: isLoadingMutate } =
    useAddGuestAsPublicUser({
      refetchFn: closeAddGuestsModal,
    } as IAddGuestAsPublicUserFilter)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()
    setRecaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify().catch((error) => console.error(error))
  }, [handleReCaptchaVerify])

  const getFieldsRequired = (): IPropsSchema => {
    const fieldsRequired = {
      cpfRequired: false,
      phoneRequired: false,
      birthdateRequired: false,
      completeAddressRequired: false,
      stateAndCityRequired: false,
      cepRequired: false,
      rgRequired: false,
      socialMediaRequired: false,
      jobPositionRequired: false,
      jobCompanyRequired: false,
    }

    if (
      !dataEventInfo?.register_fields ||
      !dataEventInfo?.register_fields.length
    )
      return fieldsRequired

    dataEventInfo?.register_fields?.map((field) => {
      const setupRegisterFiedsRequire = {
        [registerFieldsConstants.cpf]: () =>
          (fieldsRequired.cpfRequired = field.mandatory === '1'),
        [registerFieldsConstants.phone]: () =>
          (fieldsRequired.phoneRequired = field.mandatory === '1'),
        [registerFieldsConstants.birthdate]: () =>
          (fieldsRequired.birthdateRequired = field.mandatory === '1'),
        [registerFieldsConstants.completeAddress]: () =>
          (fieldsRequired.completeAddressRequired = field.mandatory === '1'),
        [registerFieldsConstants.stateAndCity]: () =>
          (fieldsRequired.stateAndCityRequired = field.mandatory === '1'),
        [registerFieldsConstants.cep]: () =>
          (fieldsRequired.cepRequired = field.mandatory === '1'),
        [registerFieldsConstants.rg]: () =>
          (fieldsRequired.rgRequired = field.mandatory === '1'),
        [registerFieldsConstants.socialMedia]: () =>
          (fieldsRequired.socialMediaRequired = field.mandatory === '1'),
        [registerFieldsConstants.jobPosition]: () =>
          (fieldsRequired.jobPositionRequired = field.mandatory === '1'),
        [registerFieldsConstants.jobCompany]: () =>
          (fieldsRequired.jobCompanyRequired = field.mandatory === '1'),
      }

      try {
        return setupRegisterFiedsRequire[field.register_field_id]()
      } catch {
        return null
      }
    })

    return fieldsRequired
  }

  const formik = useFormik({
    initialValues,
    validationSchema: getPublicAddGuestsSchema(getFieldsRequired()),
    onSubmit: (values) => {
      const {
        fullname,
        email,
        phone,
        birthdate,
        cpf,
        rg,
        cep,
        address,
        number,
        neighborhood,
        complement,
        cityId,
        jobCompany,
        jobPosition,
        socialMedia,
        guests,
        acceptTerms,
        receiveOffers,
      } = values

      const data = {
        fullname,
        email,
        phone,
        birthdate,
        cpf,
        rg,
        cep,
        address,
        number,
        neighborhood,
        complement,
        cityId,
        jobCompany,
        jobPosition,
        socialMedia,
        guests,
        acceptTerms,
        receiveOffers,
      }

      let filter: IAddGuestAsPublicUserFilter = {
        ...data,
        guests,
        listSlug,
        admUserId,
        captcha: recaptchaToken, //TODO: Pegar valor dinamicamente
      } as unknown as IAddGuestAsPublicUserFilter

      addGuestAsPublicUserMutate(filter)
    },
  })

  const defaultDateAndHourValue = ''
  const formattedDateAndHour = formatAsddddDDMMYYYYHHhmm({
    dateAndTimeString: String(dataEventInfo?.event_date),
    defaultValue: defaultDateAndHourValue,
  })
  const formattedEventCloseRegisterDateAndHour = formatAsDDMMYYYYHHhmm({
    dateAndTimeString: String(dataEventInfo?.event_close_register),
    defaultValue: defaultDateAndHourValue,
  })

  const openAddGuestsModal = () => {
    document.body.classList.add('modal-open')
    document.body.classList.add('modal-add-guests')
    // document.body.classList.remove('modal-promoter-add')
  }

  const handleAddGuestsInputChange = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key !== 'Enter') return

    event.preventDefault()

    const typedValue: string =
      document
        .querySelector('input[name="guestsNew"]')
        ?.getAttribute('value') || ''

    if (typedValue === '') return

    const namesRegexPattern = /\r?\n|, |<br ?\/?>|,/ // Este regex tem match com , e <br/> e \n e \r\n
    let formattedNamesArr: string[] =
      typedValue.toLowerCase().split(namesRegexPattern) || []

    // Garante que o nome completo de cada convidado esteja com a primeira letra de cada um dos seus nomes em caixa alta
    for (let i = 0; i < formattedNamesArr.length; i++) {
      let formattedName = formattedNamesArr[i]
      let fullNameArr: string[] = formattedName.split(' ')
      for (let n = 0; n < fullNameArr.length; n++) {
        fullNameArr[n] = fullNameArr[n][0]
          ? fullNameArr[n][0].toUpperCase() + fullNameArr[n].substr(1)
          : ''
      }
      formattedNamesArr[i] = fullNameArr.join(' ')
    }

    // O nomes devem ser separados por ;
    /** Replace com regex:
     * replace(/;+/g, ';') = remove ; repetidos consecutivamente, permitindo apenas um por vez
     * replace(/;\s*$/, '') = remove a o último ; e qualquer espaço em branco depois dele
     */
    let formattedNames: string =
      formattedNamesArr.join(';').replace(/;+/g, ';').replace(/;\s*$/, '') || ''
    const updatedGuests =
      formik.values.guests !== ''
        ? `${formik.values.guests};${formattedNames}`
        : formattedNames

    formik.setFieldValue('guests', updatedGuests)
    formik.setFieldValue('guestsNew', '')
  }

  const removeGuest = (guestName: string) => {
    const updatedGuests = formik.values.guests
      .replace(guestName, '')
      .replace(/;+/g, ';')
      .replace(/^;|;$/g, '')

    formik.setFieldValue('guests', updatedGuests)
  }

  return {
    isLoading,
    formik,
    recaptchaToken,
    addGuestAsPublicUserMutate,
    closeAddGuestsModal,
    formattedDateAndHour,
    formattedEventCloseRegisterDateAndHour,
    defaultDateAndHourValue,
    openAddGuestsModal,
    handleAddGuestsInputChange,
    removeGuest,

    //variables Event
    eventAddress: dataEventInfo?.event_address,
    eventFlyer: dataEventInfo?.event_flyer,
    eventName: dataEventInfo?.event_name,
    eventVideo: formatYoutubeUrl(dataEventInfo?.event_video || ''),
    registerFields: dataEventInfo?.register_fields || [],
    isLoadingMutate,
  }
}
