import { AxiosResponse } from 'axios'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'
import { camelCaseToSnakeCase } from 'src/resources/utils/camelCase'

import {
  IAddGuestAsPublicUserFilter,
  IAddGuestFilter,
  IDeleteGuestFilter,
  IEventGuestsListFilter,
  IEventGuestsListResponse,
  IUpdateGuestListFilter,
} from 'src/resources/types/services/guests'
import { IServiceResponse } from 'src/resources/types/services'

export const GuestsService = {
  getEventGuestsList: async (
    filter: IEventGuestsListFilter
  ): Promise<IEventGuestsListResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'getUsersByEvent',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.events.getListUsers,
      data
    )
    const formattadeData: IEventGuestsListResponse = {
      msg: response?.data?.msg,
      success: response?.data?.success,
      data: {
        guests: response?.data?.data || [],
        totalResults: response?.data?.qtt || 0,
      },
    }

    return formattadeData
  },
  updateGuestList: async (
    filter: IUpdateGuestListFilter
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'updateUserList',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.lists.updateUserList,
      data
    )
    return response?.data
  },
  deleteGuest: async (
    filter: IDeleteGuestFilter
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'updateUserList',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.lists.updateUserList,
      data
    )
    return response?.data
  },
  addGuest: async (filter: IAddGuestFilter): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'addUsers',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.lists.addUsers,
      data
    )
    return response?.data
  },
  addGuestAsPublicUser: async (
    filter: IAddGuestAsPublicUserFilter
  ): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'registerUser',
      ...camelCaseToSnakeCase(filter),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.public.users.register,
      data
    )
    return response?.data
  },
}
