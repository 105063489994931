/* eslint-disable no-useless-escape */
import * as Yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'
import dayjs from 'dayjs'

import { IPropsSchema } from './types'

export const getPublicAddGuestsSchema = ({
  cpfRequired = false,
  phoneRequired = false,
  birthdateRequired = false,
  completeAddressRequired = false,
  stateAndCityRequired = false,
  cepRequired = false,
  rgRequired = false,
  socialMediaRequired = false,
  jobPositionRequired = false,
  jobCompanyRequired = false,
}: IPropsSchema) => {
  const publicAddGuestsSchema = Yup.object().shape({
    fullname: Yup.string()
      .matches(
        /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+( [A-Za-zÀ-ÖØ-öø-ÿ\s-]+)*$/,
        'Por favor, insira apenas letras do alfabeto'
      )
      .test('familyName', 'Por favor, insira seu nome completo', (val) => {
        if (!val) return true

        var stringsList = val
          .split(/(\s+)/)
          .filter((item) => item.trim().length > 0)
        var isValid = stringsList.length > 1

        return isValid
      })
      .required('Nome completo é obrigatório'),
    email: Yup.string()
      .email('Por favor, insira um email válido')
      .required('Email é obrigatório'),
    rg: rgRequired
      ? Yup.string()
          .min(5, 'O RG tem o mínimo de 5 dígitos')
          .max(15, 'O RG tem o máximo de 15 dígitos')
          .required('RG é obrigatório')
      : Yup.string()
          .min(5, 'O RG tem o mínimo de 5 dígitos')
          .max(15, 'O RG tem o máximo de 15 dígitos')
          .notRequired(),

    phone: phoneRequired
      ? Yup.string()
          .matches(
            /^[0-9]+$/,
            'Por favor, insira apenas números sem espaços e caracteres especiais'
          )
          .test('mask', 'Número de telefone inválido', (val) => {
            if (val?.includes('_') || !val) return false

            return true
          })
          .required('Telefone é obrigatório')
      : Yup.string()
          .matches(
            /^[0-9]+$/,
            'Por favor, insira apenas números sem espaços e caracteres especiais'
          )
          .test('mask', 'Número de telefone inválido', (val) => {
            if (!val) return true
            if (val?.includes('_')) return false

            return true
          })
          .notRequired(),
    cpf: cpfRequired
      ? Yup.string()
          .test('mask', 'CPF inválido', (val) => {
            if (val?.includes('_') || !val) return false

            const cpfIsNumber = val?.replace(/[\.\-\+,]/g, '')

            const isValid = cpf.isValid(String(cpfIsNumber))

            return isValid
          })
          .required('CPF é obrigatório')
      : Yup.string()
          .test('mask', 'CPF inválido', (val) => {
            if (!val) return true
            if (val?.includes('_')) return false

            const cpfIsNumber = val?.replace(/[\.\-\+,]/g, '')

            const isValid = cpf.isValid(String(cpfIsNumber))

            return isValid
          })
          .notRequired(),
    birthdate: birthdateRequired
      ? Yup.string()
          .test('mask', 'Data de nascimento inválida', (val) => {
            if (val?.includes('_') || !val) return false

            const date = val?.replace(/[.\-+,]/g, '').split('/')

            const dateFormated = new Date(`${date[2]}-${date[1]}-${date[0]}`)

            const isValid = dayjs(dateFormated, 'YYYY-MM-DD').isValid()

            return isValid
          })
          .required('Data de nascimento é obrigatória')
      : Yup.string()
          .test('mask', 'Data de nascimento inválida', (val) => {
            if (!val) return true
            if (val?.includes('_')) return false

            const date = val?.replace(/[.\-+,]/g, '').split('/')

            const dateFormated = new Date(`${date[2]}-${date[1]}-${date[0]}`)

            const isValid = dayjs(dateFormated, 'YYYY-MM-DD').isValid()

            return isValid
          })
          .notRequired(),

    cep: cepRequired
      ? Yup.string()
          .test('mask', 'CEP inválido', (val) => {
            if (val?.includes('_') || !val) return false

            return true
          })
          .required('CEP é obrigatório')
      : Yup.string()
          .test('mask', 'CEP inválido', (val) => {
            if (!val) return true
            if (val?.includes('_')) return false

            return true
          })
          .notRequired(),
    address: completeAddressRequired
      ? Yup.string()
          .min(2, 'O endereço deve ter o mínimo de 3 dígitos')
          .required('Endereço é obrigatório')
      : Yup.string()
          .min(2, 'O endereço deve ter o mínimo de 2 dígitos')
          .notRequired(),

    neighborhood: completeAddressRequired
      ? Yup.string()
          .min(2, 'O bairro deve ter o mínimo de 3 dígitos')
          .required('bairro é obrigatório')
      : Yup.string()
          .min(2, 'O bairro deve ter o mínimo de 2 dígitos')
          .notRequired(),
    stateId: stateAndCityRequired
      ? Yup.string().required('Estado é obrigatório')
      : Yup.string().notRequired(),
    cityId: stateAndCityRequired
      ? Yup.string().required('Cidade é obrigatório')
      : Yup.string().notRequired(),
    jobCompany: jobCompanyRequired
      ? Yup.string()
          .min(2, 'O nome da empresa deve ter o mínimo de 2 dígitos')
          .required('Nome da empresa é obrigatório')
      : Yup.string()
          .min(2, 'O nome da empresa deve ter o mínimo de 2 dígitos')
          .notRequired(),
    jobPosition: jobPositionRequired
      ? Yup.string()
          .min(2, 'O cargo deve ter o mínimo de 2 dígitos')
          .required('cargo é obrigatório')
      : Yup.string()
          .min(2, 'O cargo deve ter o mínimo de 2 dígitos')
          .notRequired(),
    socialMedia: socialMediaRequired
      ? Yup.string()
          .min(2, 'A rede social deve ter o mínimo de 2 dígitos')
          .required('Rede social é obrigatória')
      : Yup.string()
          .min(2, 'A rede social deve ter o mínimo de 2 dígitos')
          .notRequired(),
    guestsNew: Yup.string().optional(),
    guests: Yup.string().optional(),
    acceptTerms: Yup.string().oneOf(
      ['Y'],
      'Você deve aceitar a política de privacidade'
    ),
    receiveOffers: Yup.string().oneOf(['Y', 'N']).optional(),
  })

  return publicAddGuestsSchema
}
