import { useEffect, useState } from 'react'

import { useGetEventGuestsList } from 'src/hooks/guests/useGuests'
import { IEventGuestsListFilter } from 'src/resources/types/services/guests'

import { useAuth } from 'src/app/modules/auth'
import { useParams } from 'react-router-dom'
import { useGetEventInfo } from 'src/hooks/events/useEvents'
import { useCheckin } from 'src/hooks/checkin/useCheckin'
import { IEventCheckinParams } from 'src/resources/types/services/checkin'
import { IAuthUser } from '@resources/types/services/auth'

export const useEventSingleCheckin = (): any => {
  const { currentUser, setCurrentUser } = useAuth()
  const routeParams = useParams()

  const [eventGuestListPage, setEventGuestListPage] = useState<number>(1)
  const eventId: number = Number(routeParams.eventId)
  const accountSlug: string = currentUser?.eventAccountSlug || ''
  const userId: number = Number(currentUser?.user_id)
  const [eventGuestsListFilter, setEventGuestsListFilter] = useState<
    IEventGuestsListFilter | {}
  >({ accountSlug, eventId, page: eventGuestListPage })

  const { data: eventInfoData } = useGetEventInfo({
    accountSlug,
    eventId,
  })

  const {
    data: eventGuestsListData,
    isLoading: isLoadingEventGuestsList,
    refetch: refetchEventGuestsList,
  } = useGetEventGuestsList(eventGuestsListFilter as IEventGuestsListFilter)

  const { mutate: listCheckinMutate } = useCheckin({
    refetchFn: refetchEventGuestsList,
  } as unknown as IEventCheckinParams)

  useEffect(() => {
    refetchEventGuestsList()
  }, [eventGuestsListFilter, refetchEventGuestsList])

  useEffect(() => {
    const setCurrentUserAdmLevelId = (): void => {
      const eventLevelOfLoggedUserObj = eventInfoData?.events_levels.filter(
        (level) =>
          level.users.some((user) => user.adm_user_id === currentUser?.user_id)
      )
      const currentAdmLevelId: number | null = eventLevelOfLoggedUserObj
        ? eventLevelOfLoggedUserObj[0]?.adm_level_id
        : null

      if (currentUser?.admLevelId === currentAdmLevelId) return

      setCurrentUser({
        ...currentUser,
        admLevelId: currentAdmLevelId,
      } as IAuthUser)
    }

    setCurrentUserAdmLevelId()
  }, [currentUser, eventInfoData, setCurrentUser, eventId])

  return {
    accountSlug,
    eventId,
    setEventGuestListPage,
    eventGuestsListFilter,
    setEventGuestsListFilter,
    eventGuestsListData,
    isLoadingEventGuestsList,
    refetchEventGuestsList,
    eventInfoData,
    listCheckinMutate,
    userId,
  }
}
