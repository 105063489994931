import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {
  AUTH_GENERIC_ROUTE,
  BUILDER_ROUTE,
  CHAT_GENERIC_ROUTE,
  CRAFTED_ACCOUNT_GENERIC_ROUTE,
  CRAFTED_PROFILE_GENERIC_ROUTE,
  CRAFTED_WIDGETS_GENERIC_ROUTE,
  CRAFTED_WIZARDS_GENERIC_ROUTE,
  DAHSBOARD_ROUTE,
  ERROR_404_ROUTE,
  EVENTS_GENERIC_ROUTE,
  EVENTS_LIST_ROUTE,
  EVENT_BY_ID_GENERIC_ROUTE,
  LISTS_GENERIC_ROUTE,
  MENU_TEST_ROUTE,
  PROMOTERS_GENERIC_ROUTE,
  USER_MANAGMENT_GENERIC_ROUTE,
} from 'src/constants/routes'
// import {EventsListWrapper} from '../pages/EventsList'
// import {EventsNewWrapper} from '../pages/EventsNew'
// import {ListsWrapper} from '../pages/Lists'
// import {PromotersList} from '../pages/PromotersList'

const PrivateRoutes = () => {
  const EventsPage = lazy(() => import('../pages/Events'))
  const EventsSinglePage = lazy(() => import('../pages/EventsSingle'))
  const ListsPage = lazy(() => import('../pages/Lists'))
  const PromotersPage = lazy(() => import('../pages/Promoters'))

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(
    () => import('../modules/apps/user-management/UsersPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path={AUTH_GENERIC_ROUTE}
          element={<Navigate to={EVENTS_LIST_ROUTE} />}
        />

        {/* Pages */}
        <Route path={DAHSBOARD_ROUTE} element={<DashboardWrapper />} />

        {/* <Route path='events' element={<EventsWrapper />} /> */}
        {/* <Route path='events/new' element={<EventsNewWrapper />} /> */}

        {/* <Route path='promoters' element={<PromotersList />} /> */}
        {/* <Route path='promoters/list' element={<PromotersList />} /> */}
        {/* <Route path='lists' element={<ListsWrapper />} /> */}

        <Route path={BUILDER_ROUTE} element={<BuilderPageWrapper />} />
        <Route path={MENU_TEST_ROUTE} element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path={EVENTS_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />

        <Route
          path={LISTS_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <ListsPage />
            </SuspensedView>
          }
        />

        <Route
          path={PROMOTERS_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <PromotersPage />
            </SuspensedView>
          }
        />

        <Route
          path={EVENT_BY_ID_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <EventsSinglePage />
            </SuspensedView>
          }
        />

        <Route
          path={CRAFTED_PROFILE_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path={CRAFTED_WIZARDS_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path={CRAFTED_WIDGETS_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path={CRAFTED_ACCOUNT_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path={CHAT_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path={USER_MANAGMENT_GENERIC_ROUTE}
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to={ERROR_404_ROUTE} />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
