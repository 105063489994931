import { IEventCheckinParams } from 'src/resources/types/services/checkin'
import { CheckinService } from 'src/services/checkin'
import { UseMutationResult, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { IServiceResponse } from 'src/resources/types/services'
import { showErrorMessage } from 'src/resources/utils/response'

export const useCheckin = (
  options: IEventCheckinParams
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (options) => CheckinService.checkin(options),
    onSuccess: (response) => {
      const { refetchFn } = options

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}
