import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { AuthService } from 'src/services/auth'

import {
  IAuthLoginRequest,
  IAuthLoginErrorResponse,
  IAuthUser,
} from 'src/resources/types/services/auth'
import { IMutationOptionsRequest } from 'src/resources/types/hooks/mutation'

export const useAuthLogin = (
  options?: IMutationOptionsRequest
): UseMutationResult<
  IAuthUser | IAuthLoginErrorResponse,
  any,
  IAuthLoginRequest,
  unknown
> => {
  return useMutation(AuthService.login, options)
}

export const useAuthAccount = (): UseQueryResult<IAuthUser> => {
  return useQuery(['authUser'])
}
