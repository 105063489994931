import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { useLogin } from './useLogin'
import { FORGOT_PASSWORD_ROUTE, REGISTRATION_ROUTE } from 'src/constants/routes'
import { getCurrentUrlParams } from 'src/resources/utils/url'

export function Login() {
  const { formik, isLoading, errorMenssage } = useLogin()

  const urlParams: any = getCurrentUrlParams([
    {
      name: 'eventLevelHash',
      defaultValue: '-',
    },
  ])

  const { eventLevelHash } = urlParams

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-5'>
        <h1 className='text-dark fw-bolder mb-3'>Login</h1>
      </div>

      {!!errorMenssage && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{errorMenssage}</div>
        </div>
      )}

      {/* <div className='mb-5 bg-light-info p-8 rounded'>
        <div className='text-info'>
          Use conta <strong>admin@demo.com</strong> e senha{' '}
          <strong>demo</strong> para continuar.
        </div>
      </div> */}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>
          Senha
        </label>
        <input
          type='password'
          placeholder='Senha'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        <Link to={FORGOT_PASSWORD_ROUTE} className='link-primary'>
          Esqueceu a senha ?
        </Link>
      </div>
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!isLoading && <span className='indicator-label'>Entrar</span>}
          {isLoading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Por favor espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Ainda não tem cadastro?{' '}
        <Link
          to={`${REGISTRATION_ROUTE}${
            eventLevelHash !== '-' ? '?eventLevelHash=' + eventLevelHash : ''
          }`}
          className='link-primary'
        >
          Criar Conta
        </Link>
      </div>
    </form>
  )
}
