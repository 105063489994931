export const endpoints = {
  URL: {
    admin: {
      account: {
        register: 'ws_admin/account_register',
        login: 'ws_admin/account_login',
        acceptInviteByRegister: 'ws_admin/account_register',
        acceptInviteByLogin: 'ws_admin/account_login',
        forgotPasswordSendTokenToEmail: 'ws_admin/account_forgot_pwd',
        forgotPasswordUpdateByToken: 'ws_admin/account_forgot_pwd',
        updatePasswordAdm: 'ws_admin/account_profile',
        updateProfileAccount: 'ws_admin/account_profile',
        updateProfileAdm: 'ws_admin/account_profile',
        deleteAdmUser: 'ws_admin/account_profile',
        deleteAccount: 'ws_admin/account_profile',
        uploadAdmAvatar: 'ws_admin/account_profile',
        getAdmUsers: 'ws_admin/events',
      },
      events: {
        getEvents: 'ws_admin/events',
        getEvent: 'ws_admin/events',
        uploadEventImage: 'ws_admin/events',
        createEvent: 'ws_admin/events',
        updateEvent: 'ws_admin/events',
        deleteAdmUserEvent: 'ws_admin/events',
        updateAdmUserEvent: 'ws_admin/events',
        getRegisterFiels: 'ws_admin/events',
        getListUsers: 'ws_admin/events',
        exportListUsers: 'ws_admin/export',
        validateCoupon: 'ws_admin/events',
      },
      lists: {
        getLists: 'ws_admin/lists',
        getList: 'ws_admin/lists',
        createList: 'ws_admin/lists',
        updateList: 'ws_admin/lists',
        deleteList: 'ws_admin/lists',
        addUsers: 'ws_admin/lists',
        checkin: 'ws_admin/lists',
        updateUserList: 'ws_admin/lists',
      },
      dashboard: {
        getAdmGuestRanking: 'ws_admin/dashboard',
        getGuestRankingList: 'ws_admin/dashboard',
        getAdmCheckinRanking: 'ws_admin/dashboard',
        getCheckinsByHour: 'ws_admin/dashboard',
      },
    },
    public: {
      address: {
        getByCep: 'ws/users',
        getStates: 'ws/users',
        getCitiesByState: 'ws/users',
      },
      lists: {
        getListPage: 'ws/lists',
        getEventInvitePage: 'ws/lists',
      },
      users: {
        register: 'ws/users',
      },
    },
  },
}
