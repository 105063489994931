/* eslint-disable no-prototype-builtins */
import lodash from 'lodash'

export const camelCaseToSnakeCase = (obj: any) => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const result: any = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeCaseKey = lodash.snakeCase(key)
      result[snakeCaseKey] = camelCaseToSnakeCase(obj[key])
    }
  }
  return result
}
