//TODO: Inserir tipagem
export const initialValues = {
  fullname: '',
  email: '',
  phone: '',
  birthdate: '',
  cpf: '',
  rg: '',
  cep: '',
  address: '',
  number: '',
  neighborhood: '',
  complement: '',
  stateId: '',
  cityId: '',
  jobCompany: '',
  jobPosition: '',
  socialMedia: '',
  guestsNew: '',
  guests: '',
  acceptTerms: 'N',
  receiveOffers: 'N',
}
