import { useAddress } from './useAddress'
import { IProps } from './types'

const FieldAddress = ({ field, formik, ...props }: IProps) => {
  useAddress({ formik })

  return (
    <>
      <div className='fv-row mb-10'>
        {/*begin::Label*/}
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className={field.mandatory === '1' ? 'required' : ''}>
            Endereço
          </span>
          <span
            className='ms-1'
            data-bs-toggle='tooltip'
            title={`Digite o Endereço`}
          >
            {field.mandatory === '1' && (
              <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
              </i>
            )}
          </span>
        </label>
        {/*end::Label*/}
        {/*begin::Input*/}
        <input
          type='text'
          className={`form-control form-control-lg form-control-solid pt-3 ${
            formik.touched.address && formik.errors.address ? 'is-invalid' : ''
          }`}
          placeholder='Digite o Endereço'
          {...props}
        />

        {formik.touched.address && formik.errors.address && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.address}</div>
          </div>
        )}
        {/*end::Input*/}
      </div>
    </>
  )
}

export default FieldAddress
