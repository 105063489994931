import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { LOGIN_ROUTE } from 'src/constants/routes'

import { useForgotPassword } from './useForgotPassword'

export const ForgotPassword = () => {
  const { formik, loading, hasErrors, isSuccess, msgErrors } =
    useForgotPassword()

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Esqueceu a senha?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Digite seu e-mail para resetar sua senha.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors && (
        <div className='mb-lg-5 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {msgErrors.join('\n')}
          </div>
        </div>
      )}

      {isSuccess && (
        <div className='mb-10 bg-light-info p-5 rounded'>
          <div className='text-info'>
            E-mail de recuperação enviado. Verifique sua caixa de e-mails.
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={!formik.touched.email || !!formik.errors.email}
        >
          <span className='indicator-label'>Enviar</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to={LOGIN_ROUTE}>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
