import { useEffect, useState, useCallback } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate } from 'react-router-dom'

import { PasswordMeterComponent } from '../../../../../_metronic/assets/ts/components'
import {
  useAccountSendTwoFactore,
  useAccountRegister,
} from 'src/hooks/account/useAccount'

import { initialValues, registrationSchema } from './form'
import { getCurrentUrlParams } from 'src/resources/utils/url'
import { showAcceptStaffInviteSuccessMessage } from 'src/resources/utils/staff'
import { IAcceptStaffInviteUrlParams } from 'src/resources/types/modules/auth'
import { useIntl } from 'react-intl'

export const useRegistration = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { mutate: mutateSendTwoFactore, isLoading: isLoadingTwoFactore } =
    useAccountSendTwoFactore()
  const { mutate: mutateRegisterUser, isLoading: isLoadingRegister } =
    useAccountRegister()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [recaptchaToken, setRecaptchaToken] = useState('')
  const [displayModal, setDisplayModal] = useState(false)
  const [codeEmailInvalid, setCodeEmailInvalid] = useState(false)

  const handleModalTwoFactore = () => {
    setDisplayModal(!displayModal)
  }

  const handleSuccessPt1 = () => {
    handleModalTwoFactore()
  }

  const handleSuccessPt2 = (data: { msg: string; success: number }) => {
    handleModalTwoFactore()

    toast.success(data.msg)

    navigate('/auth')

    showAcceptStaffInviteSuccessMessage({
      eventLevelHash,
      eventName,
      accessLevelId,
      intl,
    })
  }

  const onSuccess = (data: { msg: string; success: number; errors?: any }) => {
    if (!data.success) {
      const keys: any = Object.keys(data.errors)

      keys.forEach((key: string) => {
        if (key === 'email_code') {
          setCodeEmailInvalid(true)
        }

        toast.error(data.errors[key])
      })

      return
    }

    if (!formik.values.emailCode) {
      handleSuccessPt1()

      return
    }

    handleSuccessPt2(data)
  }

  const onError = (error: any) => {
    toast.error(error.msg)
  }

  //TODO: Melhorar tipagem
  const urlParams: any = getCurrentUrlParams([
    {
      name: 'eventLevelHash',
      defaultValue: undefined,
    },
    {
      name: 'accessLevelId',
      defaultValue: null,
    },
    {
      name: 'eventName',
      defaultValue: '',
    },
  ]) as IAcceptStaffInviteUrlParams

  const { eventLevelHash, accessLevelId, eventName } = urlParams

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      try {
        values.captcha = recaptchaToken
        values.eventLevelHash = eventLevelHash

        if (!values.emailCode) {
          mutateSendTwoFactore(values, {
            onSuccess,
            onError,
          })

          return
        }

        mutateRegisterUser(values, {
          onSuccess,
          onError,
        })
      } catch (error) {
        console.error(error)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha()
    setRecaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify().catch((error) => console.error(error))
  }, [handleReCaptchaVerify])

  useEffect(() => {
    if (!formik.values.emailCode.length) {
      setCodeEmailInvalid(false)
    }
  }, [formik.values.emailCode])

  const onChangeInputCode = (code: string) => {
    formik.setFieldValue('emailCode', code)
  }

  return {
    formik,
    isLoading: isLoadingTwoFactore || isLoadingRegister,
    displayModal,
    handleModalTwoFactore,
    codeEmailInvalid,
    onChangeInputCode,
  }
}
