import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { PageTitle } from '../../_metronic/layout/core'
import { useLayout } from '../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { getAdmLevelById } from 'src/resources/utils/staff'
import {
  AdmLevelId,
  IEventsSingleAddStaffPage,
  IEventsSingleAddStaffPageUrlParams,
} from 'src/resources/types/pages/staff'
import {
  FLYER_URL,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
} from 'src/constants/routes'
import { getCurrentUrlParams } from 'src/resources/utils/url'
import { formatAsddddDDMMYYYYHHhmm } from 'src/resources/utils/dateAndTime'
import { useAuth } from 'src/app/modules/auth'
import { useGetStaffInvitePage } from 'src/hooks/staff/useStaff'

const EventsSingleAddStaffPage: FC<IEventsSingleAddStaffPage> = (props) => {
  const { logout } = useAuth()
  const { config } = useLayout()
  const { intl } = props

  const urlParams: IEventsSingleAddStaffPageUrlParams = getCurrentUrlParams([
    {
      name: 'slug',
      defaultValue: 'null',
    },
    {
      name: 'level',
      defaultValue: 'null',
    },
  ]) as IEventsSingleAddStaffPageUrlParams

  const { slug: accountSlug, level: eventLevelHash } = urlParams

  const { data: staffInvitePageData } = useGetStaffInvitePage({
    accountSlug,
    eventLevelHash,
  })

  const defaultDateAndHourValue = ''

  const [accessLevelId, setAccessLevelId] = useState<AdmLevelId | null>(null)
  const [eventName, setEventName] = useState<string>('-')
  const [eventFlyer, setEventFlyer] = useState<string>('')
  const [eventAddress, setEventAddress] = useState<string>('')
  const [formattedDateAndHour, setFormattedDateAndHour] = useState<string>(
    defaultDateAndHourValue
  )

  useEffect(() => {
    if (!staffInvitePageData) return

    const { adm_level_id, event_name, event_date, event_flyer, event_address } =
      staffInvitePageData

    const dateAndHour = formatAsddddDDMMYYYYHHhmm({
      dateAndTimeString: event_date,
      defaultValue: defaultDateAndHourValue,
    })

    setAccessLevelId(adm_level_id)
    setEventName(event_name)
    setFormattedDateAndHour(dateAndHour)
    setEventFlyer(event_flyer)
    setEventAddress(event_address)
  }, [staffInvitePageData])

  if (!config.app?.toolbar?.display) {
    return null
  }

  return (
    <>
      <div className='row g-6 g-xl-9'>
        <div className='col-md-6 col-xl6 m-0'>
          <div className='flyer-container mb-5'>
            <img
              src={`${FLYER_URL}/${eventFlyer}`}
              className='w-100 rounded'
              alt=''
            />
          </div>
        </div>

        <div className='col-md-6 col-xl6 m-0 px-20 d-flex flex-column justify-content-center'>
          {/* begin::Logo */}
          <a href='#' className='mb-10'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-backstage.svg')}
              className='h-35px'
            />
          </a>
          {/* end::Logo */}

          <h3 className='text-light bs-fs-2 mb-5'>
            Você foi convidado(a) para ser <br />
            {getAdmLevelById({
              accessLevelId,
              intl,
            })}{' '}
            do evento:
          </h3>

          <h1 className='text-primary bs-fs-1 mb-3'>{eventName}</h1>

          <h3 className='text-light bs-fs-2 mb-5'>
            {formattedDateAndHour}
            {formattedDateAndHour !== defaultDateAndHourValue && <br />}
            {eventAddress}
          </h3>

          <div className='row mt-5'>
            <div className='col-12'>
              <div className='button-holder'>
                <Link
                  className={`btn btn-primary`}
                  to={`${LOGIN_ROUTE}?eventLevelHash=${eventLevelHash}`}
                  onClick={() => {
                    logout()
                  }}
                >
                  <span className='indicator-label'>
                    Faça Login para Aceitar o Convite
                  </span>
                </Link>

                <div className='event-desc text-light mt-2'>
                  Não tem cadastro?{' '}
                  <Link
                    to={`${REGISTRATION_ROUTE}?eventLevelHash=${eventLevelHash}`}
                    onClick={() => {
                      logout()
                    }}
                  >
                    Cadastre-se
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EventSingleAddStaff: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.EVENTS' })}
      </PageTitle>
      <EventsSingleAddStaffPage intl={intl} />
    </>
  )
}

export { EventSingleAddStaff }
