import { AxiosResponse } from 'axios'

import { endpoints } from 'src/services/api/endpoints'
import { getApiInstance } from 'src/services/api'
import { camelCaseToSnakeCase } from 'src/resources/utils/camelCase'
import { IEventCheckinParams } from 'src/resources/types/services/checkin'
import { IServiceResponse } from 'src/resources/types/services'

export const CheckinService = {
  checkin: async (params: IEventCheckinParams): Promise<IServiceResponse> => {
    const api = getApiInstance()
    const data = {
      action: 'checkin',
      ...camelCaseToSnakeCase(params),
    }
    const response: AxiosResponse = await api.post(
      endpoints.URL.admin.lists.checkin,
      data
    )
    return response?.data
  },
}
