import { useEffect } from 'react'

import { useLocationAddressByCep } from 'src/hooks/location/useLocation'

import { IUseAddressProps } from '../types'

export const useAddress = ({ formik }: IUseAddressProps) => {
  const { data } = useLocationAddressByCep()

  useEffect(() => {
    if (!data?.address_data?.address) return

    formik.setFieldValue('address', data.address_data.address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
