import { KTIcon } from 'src/_metronic/helpers'
import ReactCodeInput from 'react-code-input'

import { IProps } from './types'
import { useCallback, useEffect } from 'react'

const ModalTwoFactor = ({
  displayModal,
  handleModalTwoFactore,
  codeEmailInvalid,
  isLoading,
  handleSubmit,
  disabled,
  onChangeInputCode,
  value,
  name,
  instructionText,
}: IProps) => {
  const openModal = useCallback(() => {
    document.body.classList.add('modal-open')
    document.body.classList.add('modal-promoter-add')
  }, [])

  const closeModal = () => {
    document.body.classList.remove('modal-open')
    document.body.classList.remove('modal-promoter-add')
    document.body.classList.remove('modal-promoter-edit')

    onChangeInputCode('')

    handleModalTwoFactore()
  }

  useEffect(() => {
    if (displayModal) {
      onChangeInputCode('')
      openModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayModal, openModal])

  return (
    <>
      {/* MODAL ADICIONAR */}
      <div className='modal_container modal-promoter-add'>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_promoter'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
          {/* begin::Modal dialog */}
          <div className='modal-dialog modal-dialog-centered mw-650px'>
            {/* begin::Modal content */}
            <div className='modal-content'>
              <div className='modal-header'>
                {/* begin::Modal title */}
                <h2 className='fw-bolder'>Verificação de Dois Fatores</h2>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={closeModal}
                  style={{ cursor: 'pointer' }}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
                {/* ed::Close */}
              </div>
              {/* begin::Modal body */}
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <div className='card-body pb-5' id='kt_chat_contacts_body'>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={handleSubmit}
                  >
                    <div className='text-center mb-10'>
                      <div
                        className='text-gray-500 w-100 m-auto fw-semibold fs-5'
                        dangerouslySetInnerHTML={{
                          __html: instructionText.replace(/\n/g, '<br />'),
                        }}
                      />
                    </div>

                    <div className='mb-10 w-75 m-auto '>
                      <div className='fw-bold text-start text-dark fs-6 mb-0 ms-1'>
                        Digite o código de segurança de 6 digitos
                      </div>
                      <div className='d-flex flex-wrap flex-stack'>
                        <ReactCodeInput
                          name={name}
                          type='number'
                          isValid={true}
                          fields={6}
                          value={value}
                          onChange={(code) => {
                            onChangeInputCode(code)
                          }}
                          inputStyle={{
                            fontFamily: 'monospace',
                            MozAppearance: 'textfield',
                            borderRadius: '6px',
                            border: '1px solid',
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
                            margin: '4px',
                            paddingLeft: '8px',
                            width: '52px',
                            height: '50px',
                            fontSize: '32px',
                            boxSizing: 'border-box',
                            color: 'black',
                            textAlign: 'center',
                            backgroundColor: codeEmailInvalid
                              ? '#F2DEDE'
                              : 'white',
                            borderColor: 'lightgrey',
                          }}
                          inputMode={'email'}
                        />

                        {/* <input
                          type='text'
                          name='code_1'
                          placeholder=''
                          autoComplete='off'
                          className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
                        />
                        <input
                          type='text'
                          name='code_2'
                          placeholder=''
                          autoComplete='off'
                          className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
                        />
                        <input
                          type='text'
                          name='code_3'
                          placeholder=''
                          autoComplete='off'
                          className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
                        />
                        <input
                          type='text'
                          name='code_4'
                          placeholder=''
                          autoComplete='off'
                          className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
                        />
                        <input
                          type='text'
                          name='code_5'
                          placeholder=''
                          autoComplete='off'
                          className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
                        />
                        <input
                          type='text'
                          name='code_6'
                          placeholder=''
                          autoComplete='off'
                          className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
                        /> */}
                      </div>
                    </div>

                    {/* begin::Form group */}
                    <div className='d-flex flex-wrap flex-column justify-content-center align-items-center pb-lg-0'>
                      <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-primary w-100'
                        disabled={disabled}
                      >
                        <span className='indicator-label'>
                          {isLoading ? 'Validando...' : 'Enviar'}
                        </span>
                      </button>

                      <div className='text-gray-500 text-center mt-5 w-75 m-auto fw-semibold fs-6'>
                        Não recebeu o código?{' '}
                        <a href='javascript:;' onClick={handleSubmit}>
                          Reenviar
                        </a>
                        .
                      </div>
                    </div>
                    {/* end::Form group */}
                  </form>
                </div>
              </div>
              {/* end::Modal body */}
            </div>
            {/* end::Modal content */}
          </div>
          {/* end::Modal dialog */}
        </div>
        {/* begin::Modal Backdrop */}
        <div
          className='modal-backdrop fade show'
          id='kt_modal_add_promoter_bg'
        ></div>
        {/* end::Modal Backdrop */}
      </div>
      {/* MODAL ADICIONAR */}
    </>
  )
}

export default ModalTwoFactor
