import axios, { AxiosInstance } from 'axios'

import * as authHelper from 'src/app/modules/auth/core/AuthHelpers'

import { ApiGateway } from './config/gateway'
import { GENERIC_LOGIN_ROUTE } from 'src/constants/routes'

let instance: AxiosInstance | null = null

const checkInstanceAuth = (instanceItem: AxiosInstance) => {
  instanceItem.interceptors.request.use(
    async (config: any) => {
      if (!config.headers) return Promise.reject(config.headers)

      const bkgtoken = authHelper.getAuth()

      if (!!bkgtoken && !!config.headers)
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${bkgtoken}`,
        }

      return Promise.resolve(config)
    },
    async (error) => {
      return Promise.reject(error)
    }
  )
}

const checkResponseAuth = (instanceItem: AxiosInstance) => {
  instanceItem.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.response === undefined) {
        return Promise.reject({
          message: error.message,
          config: error.config,
        })
      }
      const originalRequest = error.config
      const status = error.response.status

      if (status === 401 || status === 403) {
        authHelper.removeAuth()
        window.location.pathname = GENERIC_LOGIN_ROUTE
      }

      if (!originalRequest._retry) {
        originalRequest._retry = true
      }
    }
  )
}

export const getApiInstance = () => {
  if (instance) {
    checkInstanceAuth(instance)
    checkResponseAuth(instance)

    return instance
  }

  const INITIAL_SETTING = ApiGateway()
  axios.defaults.withCredentials = true
  instance = axios.create(INITIAL_SETTING)

  checkInstanceAuth(instance)
  checkResponseAuth(instance)

  return instance
}
