import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from 'react-query'

import { EventService } from 'src/services/events'
import { IServiceResponse } from '../../resources/types/services'
import {
  IEventsListFilter,
  IEventListResponse,
  IEventFieldList,
  IEventUploadBannerResponse,
  IEventInfoFilter,
  IEventInfoData,
  IEventInfoDataParamsCamelCase,
  IEventValidateCouponFilter,
} from 'src/resources/types/services/events'
import { IMutationOptionsRequest } from 'src/resources/types/hooks/mutation'
import {
  handleAPIReponse,
  showErrorMessage,
} from 'src/resources/utils/response'
import { toast } from 'react-toastify'

export const useEventGetList = (
  IEventsListFilter: IEventsListFilter
): UseQueryResult<IEventListResponse> => {
  return useQuery(
    ['getEventList'],
    () => {
      return EventService.list(IEventsListFilter)
    },
    {
      /**
       * Para garantir dados mais atualizados refaz a requisição:
       * toda vez que o usuário entra na aba do navegador que mostra os dados
       * e de 1 em 1 minuto
       * */
      refetchOnWindowFocus: true,
      refetchInterval: 1 * (60 * 1000), // 60000ms = 1 minuto
    }
  )
}

export const useEventGetFieldList = (): UseQueryResult<IEventFieldList[]> => {
  return useQuery(['getEventFieldList'], () => {
    return EventService.getFieldList()
  })
}

export const useEventGetFieldListByEventId = (
  eventId = 0
): UseQueryResult<IEventFieldList[]> => {
  return useQuery(
    ['getFieldListByEvent'],
    () => {
      return EventService.getFieldList(eventId)
    },
    {
      enabled: !!eventId,
    }
  )
}

export const useEventUploadBanner = (): UseMutationResult<
  IEventUploadBannerResponse,
  any,
  any,
  unknown
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => EventService.uploadBanner(data),
    onSuccess: (response) => {
      queryClient.setQueryData(['uploadBanner'], response)
    },
    onError: (response) => {
      queryClient.setQueryData(['uploadBanner'], response)
    },
  })
}

export const useEventGetUploadBanner =
  (): UseQueryResult<IEventUploadBannerResponse> => {
    return useQuery(['uploadBanner'])
  }

export const useEventSave = (
  options?: IMutationOptionsRequest
): UseMutationResult<any, any, any, unknown> => {
  return useMutation(EventService.save, options)
}

export const useGetEventInfo = (
  eventInfoFilter: IEventInfoFilter
): UseQueryResult<IEventInfoData> => {
  return useQuery(
    ['useGetEventInfo'],
    () => {
      return EventService.getEventInfo(eventInfoFilter).then((response) =>
        handleAPIReponse(response)
      )
    },
    {
      /**
       * Para garantir dados mais atualizados refaz a requisição:
       * toda vez que o usuário entra na aba do navegador que mostra os dados
       * e de 1 em 1 minuto
       * */
      refetchOnWindowFocus: true,
      refetchInterval: 1 * (60 * 1000), // 60000ms = 1 minuto
    }
  )
}

export const useUpdateEventInfo = (
  options?: IMutationOptionsRequest
): UseMutationResult<
  IServiceResponse,
  IEventInfoDataParamsCamelCase,
  any,
  unknown
> => {
  return useMutation(EventService.updateEventInfo, options)
}

export const useEventGetListPageBySlugList = (
  slugList = ''
): UseQueryResult<IEventInfoData> => {
  return useQuery(
    ['getFieldListByEvent'],
    () => {
      return EventService.getListPageBySlugList(slugList)
    },
    {
      enabled: !!slugList,
    }
  )
}

export const useEventValidateCoupon = (
  filter: IEventValidateCouponFilter
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  const { successCallbackFn, errorCallbackFn } = filter

  return useMutation({
    mutationFn: () => EventService.validateCoupon(filter),
    onSuccess: (response) => {
      if (response.success === 0) {
        if (errorCallbackFn) {
          errorCallbackFn()
        }

        showErrorMessage(response)
        return
      }

      if (successCallbackFn) {
        successCallbackFn()
      }

      toast.success(response.msg)
    },
    onError: (response) => {
      if (errorCallbackFn) {
        errorCallbackFn()
      }

      showErrorMessage(response)
    },
  })
}
