import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query'

import { GuestsService } from 'src/services/guests'
import {
  IAddGuestAsPublicUserFilter,
  IAddGuestFilter,
  IDeleteGuestFilter,
  IEventGuestsListData,
  IEventGuestsListFilter,
  IUpdateGuestListFilter,
} from 'src/resources/types/services/guests'
import { toast } from 'react-toastify'
import { IServiceResponse } from 'src/resources/types/services'
import {
  handleAPIReponse,
  showErrorMessage,
} from 'src/resources/utils/response'
// import { handleAPIReponse } from 'src/resources/utils/response'

export const useGetEventGuestsList = (
  eventGuestsListFilter: IEventGuestsListFilter
): UseQueryResult<IEventGuestsListData> => {
  return useQuery(['useGetEventGuestsList'], () => {
    return GuestsService.getEventGuestsList(eventGuestsListFilter).then(
      (response) => handleAPIReponse(response)
    )
  })
}

export const useUpdateGuestList = (
  updateGuestListFilter: IUpdateGuestListFilter
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (updateGuestListFilter) =>
      GuestsService.updateGuestList(updateGuestListFilter),
    onSuccess: (response) => {
      const { refetchFn } = updateGuestListFilter

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useDeleteGuest = (
  deleteGuestFilter: IDeleteGuestFilter
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (deleteGuestFilter) =>
      GuestsService.deleteGuest(deleteGuestFilter),
    onSuccess: (response) => {
      const { refetchFn } = deleteGuestFilter

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useAddGuest = (
  addGuestFilter: IAddGuestFilter
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (addGuestFilter) => GuestsService.addGuest(addGuestFilter),
    onSuccess: (response) => {
      const { refetchFn } = addGuestFilter

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useAddGuestAsPublicUser = (
  addGuestAsPublicUserFilter: IAddGuestAsPublicUserFilter
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (addGuestAsPublicUserFilter) =>
      GuestsService.addGuestAsPublicUser(addGuestAsPublicUserFilter),
    onSuccess: (response) => {
      const { refetchFn } = addGuestAsPublicUserFilter

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}
