import { toast } from 'react-toastify'
import { AdmLevelId } from 'src/resources/types/pages/staff'
import {
  IAcceptStaffInviteSuccessMessage,
  IGetAmdLevelById,
} from 'src/resources/types/utils/staff'

export const getAdmLevelById = (props: IGetAmdLevelById): string => {
  const { accessLevelId, intl } = props
  let userAdmLevelsName: any = {}
  let admLevelName = 'staff'

  userAdmLevelsName[`${AdmLevelId.manager}`] = intl.formatMessage({
    id: 'GLOBAL.MANAGER',
  })
  userAdmLevelsName[`${AdmLevelId.promoter}`] = intl.formatMessage({
    id: 'GLOBAL.PROMOTER',
  })
  userAdmLevelsName[`${AdmLevelId.hostess}`] = intl.formatMessage({
    id: 'GLOBAL.HOSTESS',
  })

  if (accessLevelId !== null) {
    admLevelName = userAdmLevelsName[accessLevelId] || 'staff'
  }

  return admLevelName
}

export const showAcceptStaffInviteSuccessMessage = (
  props: IAcceptStaffInviteSuccessMessage
) => {
  const { eventLevelHash, accessLevelId, eventName, intl } = props

  if (!eventLevelHash) return

  const eventLevelName = getAdmLevelById({
    accessLevelId,
    intl,
  })

  toast.success(
    `Sua solicitação para ser ${eventLevelName} no evento ${eventName} foi concluída. Por favor, aguarde a aprovação.`
  )
}
