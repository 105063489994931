import { useNeighborhood } from './useNeighborhood'
import { IProps } from './types'

const FieldAddressNeighborhood = ({ field, formik, ...props }: IProps) => {
  useNeighborhood({ formik })

  return (
    <>
      <div className='fv-row mb-10'>
        {/*begin::Label*/}
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className={field.mandatory === '1' ? 'required' : ''}>
            Bairro
          </span>
          <span
            className='ms-1'
            data-bs-toggle='tooltip'
            title={`Digite o Bairro`}
          >
            {field.mandatory === '1' && (
              <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
              </i>
            )}
          </span>
        </label>
        {/*end::Label*/}
        {/*begin::Input*/}
        <input
          type='text'
          className={`form-control form-control-lg form-control-solid pt-3 ${
            formik.touched.neighborhood && formik.errors.neighborhood
              ? 'is-invalid'
              : ''
          }`}
          placeholder='Digite o Bairro'
          {...props}
        />

        {formik.touched.neighborhood && formik.errors.neighborhood && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.neighborhood}</div>
          </div>
        )}
        {/*end::Input*/}
      </div>
    </>
  )
}

export default FieldAddressNeighborhood
