import { useMutation, UseMutationResult } from 'react-query'

import { AccountService } from 'src/services/account'
import { toast } from 'react-toastify'
import { IMutationOptionsRequest } from 'src/resources/types/hooks/mutation'
import { IServiceResponse } from '../../resources/types/services'
import {
  IDeleteAdmUserParams,
  IUpdatePassworOrEmaildParams,
  IUpdateProfileAdmParams,
  IForgotPasswordSendTokenToEmailParams,
  IForgotPasswordUpdateByTokenParams,
  IForgotPasswordSendTokenToEmailResponse,
  IForgotPasswordUpdateByTokenResponse,
} from '../../resources/types/services/account'
import { showErrorMessage } from 'src/resources/utils/response'

export const useAccountSendTwoFactore = (
  options?: IMutationOptionsRequest
): UseMutationResult<any, any, any, unknown> => {
  return useMutation(AccountService.sendTwoFactore, options)
}

export const useAccountRegister = (
  options?: IMutationOptionsRequest
): UseMutationResult<any, any, any, unknown> => {
  return useMutation(AccountService.register, options)
}

export const useUpdateProfileDetails = (
  options: IUpdateProfileAdmParams
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (options) => AccountService.updateProfileDetails(options),
    onSuccess: (response) => {
      const { refetchFn } = options

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useUpdatePassword = (
  options: IUpdatePassworOrEmaildParams
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (options) => AccountService.updatePassword(options),
    onSuccess: (response) => {
      const { refetchFn } = options

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useDeleteAccount = (
  options: IDeleteAdmUserParams
): UseMutationResult<IServiceResponse, any, any, unknown> => {
  return useMutation({
    mutationFn: (options) => AccountService.deleteAdmUser(options),
    onSuccess: (response) => {
      const { refetchFn } = options

      if (response.success === 0) {
        showErrorMessage(response)
        return
      }

      toast.success(response.msg)

      if (refetchFn) {
        refetchFn()
      }
    },
    onError: (response) => {
      showErrorMessage(response)
    },
  })
}

export const useAccountForgotPasswordSendTokenToEmail = (
  options?: IMutationOptionsRequest
): UseMutationResult<
  IForgotPasswordSendTokenToEmailResponse,
  any,
  IForgotPasswordSendTokenToEmailParams,
  unknown
> => {
  return useMutation(AccountService.forgotPasswordSendTokenToEmail, options)
}

export const useAccountForgotPasswordUpdateByToken = (
  options?: IMutationOptionsRequest
): UseMutationResult<
  IForgotPasswordUpdateByTokenResponse,
  any,
  IForgotPasswordUpdateByTokenParams,
  unknown
> => {
  return useMutation(AccountService.forgotPasswordUpdateByToken, options)
}
