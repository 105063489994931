export const registerFieldsConstants = {
  cpf: 1,
  phone: 3,
  birthdate: 7,
  completeAddress: 8,
  stateAndCity: 10,
  cep: 11,
  rg: 12,
  jobPosition: 13,
  socialMedia: 15,
  jobCompany: 16,
}
