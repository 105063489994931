import { IProps } from './types'

const FieldJobCompany = ({ field, formik, ...props }: IProps) => {
  return (
    <div className='fv-row mb-10'>
      {/*begin::Label*/}
      <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
        <span className={field.mandatory === '1' ? 'required' : ''}>
          {field.register_field_name}
        </span>
        <span
          className='ms-1'
          data-bs-toggle='tooltip'
          title={`Digite o(a) ${field.register_field_name}`}
        >
          {field.mandatory === '1' && (
            <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
              <span className='path1' />
              <span className='path2' />
              <span className='path3' />
            </i>
          )}
        </span>
      </label>
      {/*end::Label*/}
      {/*begin::Input*/}
      <input
        type='text'
        className={`form-control form-control-lg form-control-solid pt-3 ${
          formik.touched.jobCompany && formik.errors.jobCompany
            ? 'is-invalid'
            : ''
        }`}
        placeholder='Digite o Nome da empresa'
        {...props}
      />

      {formik.touched.jobCompany && formik.errors.jobCompany && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors.jobCompany}</div>
        </div>
      )}
      {/*end::Input*/}
    </div>
  )
}

export default FieldJobCompany
