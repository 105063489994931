/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { TwoFactor } from './components/TwoFactor'
import { NewPassword } from './components/NewPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  GENERIC_FORGOT_PASSWORD_ROUTE,
  GENERIC_LOGIN_ROUTE,
  GENERIC_NEW_PASSWORD_ROUTE,
  GENERIC_REGISTRATION_ROUTE,
  GENERIC_TWO_FACTOR_ROUTE,
} from 'src/constants/routes'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bg-black bgi-position-y-bottom position-x-center bgi-no-repeat bgi-auth'
      style={{
        backgroundImage: `url(${toAbsoluteUrl(
          '/media/bgs/backstage-bg-1.jpg'
        )})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-backstage.svg')}
            className='h-45px'
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path={GENERIC_LOGIN_ROUTE} element={<Login />} />
      <Route path={GENERIC_REGISTRATION_ROUTE} element={<Registration />} />
      <Route
        path={GENERIC_FORGOT_PASSWORD_ROUTE}
        element={<ForgotPassword />}
      />
      <Route path={GENERIC_TWO_FACTOR_ROUTE} element={<TwoFactor />} />
      <Route path={GENERIC_NEW_PASSWORD_ROUTE} element={<NewPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
