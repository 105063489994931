export const initialValues = {
  slug: '',
  fullname: '',
  birthdate: '',
  phone: '',
  email: '',
  password: '',
  changePassword: '',
  acceptTerms: 'N',
  receiveOffers: 'N',
  captcha: '',
  emailCode: '',
  eventLevelHash: '',
}
