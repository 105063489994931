/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { EventRoutes } from './EventRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import {
  AUTH_GENERIC_ROUTE,
  AUTH_ROUTE,
  ERROR_GENERIC_ROUTE,
  EVENTS_LIST_ROUTE,
  EVENT_GENERIC_ROUTE,
  LOGOUT_ROUTE,
  PRIVATE_GENERIC_ROUTE,
} from 'src/constants/routes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  const urlQueryParams = window.location.search

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path={ERROR_GENERIC_ROUTE} element={<ErrorsPage />} />
          <Route path={LOGOUT_ROUTE} element={<Logout />} />

          {/* ROTA EVENT SINGLE FUNCIONA LOGADA OU DESLOGADA */}
          <Route path={EVENT_GENERIC_ROUTE} element={<EventRoutes />} />

          {currentUser ? (
            <>
              <Route path={PRIVATE_GENERIC_ROUTE} element={<PrivateRoutes />} />
              <Route index element={<Navigate to={EVENTS_LIST_ROUTE} />} />
            </>
          ) : (
            <>
              <Route path={AUTH_GENERIC_ROUTE} element={<AuthPage />} />
              <Route
                path='*'
                element={<Navigate to={`${AUTH_ROUTE}${urlQueryParams}`} />}
              />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
