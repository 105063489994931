import { useCity } from './useCity'

import { IProps } from './types'

const FieldAddressCity = ({ field, formik, ...props }: IProps) => {
  const { cities, isLoading } = useCity({ stateId: formik?.values?.stateId })

  return (
    <>
      <div className='fv-row mb-10'>
        {/*begin::Label*/}
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span className={field.mandatory === '1' ? 'required' : ''}>
            Cidade
          </span>
          <span
            className='ms-1'
            data-bs-toggle='tooltip'
            title={`Digite o(a) ${field.register_field_name}`}
          >
            {field.mandatory === '1' && (
              <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                <span className='path1' />
                <span className='path2' />
                <span className='path3' />
              </i>
            )}
          </span>
        </label>
        {/*end::Label*/}
        {/*begin::Input*/}

        <select
          className={`form-select form-select-sm form-select-solid ${
            formik.touched.cityId && formik.errors.cityId ? 'is-invalid' : ''
          }`}
          data-control='select2'
          data-placeholder='Selecione o estado'
          data-hide-search='true'
          defaultValue=''
          {...props}
        >
          <option value='' disabled>
            {isLoading ? 'Carregando cidades' : 'Selecione a cidade'}
          </option>

          {cities?.map((city, index) => (
            <option
              key={`${index}-${city.cidade_codigo}`}
              value={city.cidade_codigo}
              selected={city.cidade_codigo === formik.values.cityId}
            >
              {city.cidade_descricao}
            </option>
          ))}
        </select>

        {formik.touched.cityId && formik.errors.cityId && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.cityId}</div>
          </div>
        )}
        {/*end::Input*/}
      </div>
    </>
  )
}

export default FieldAddressCity
