import { IProps } from './types'

const FieldAddressComplement = ({ field, formik, ...props }: IProps) => {
  return (
    <>
      <div className='fv-row mb-10'>
        {/*begin::Label*/}
        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
          <span>Complemento</span>
          <span
            className='ms-1'
            data-bs-toggle='tooltip'
            title={`Digite o Complemento`}
          ></span>
        </label>
        {/*end::Label*/}
        {/*begin::Input*/}
        <input
          type='text'
          className={`form-control form-control-lg form-control-solid pt-3 ${
            formik.touched.complement && formik.errors.complement
              ? 'is-invalid'
              : ''
          }`}
          placeholder='Digite o Complemento'
          {...props}
        />

        {formik.touched.complement && formik.errors.complement && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.complement}</div>
          </div>
        )}
        {/*end::Input*/}
      </div>
    </>
  )
}

export default FieldAddressComplement
