import { useState } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { NEW_PASSWORD_ROUTE } from 'src/constants/routes'

const initialValues = {
  // email: DEMO_EMAIL || '',
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de e-mail inválido')
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Email é obrigatório'),
})

export function TwoFactor() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          Verificação de Dois Fatores!
        </h1>
        {/* end::Title */}

        <div className='text-gray-500 w-75 m-auto fw-semibold fs-6'>
          Entre abaixo o código de verificação que enviados para o e-mail que
          você forneceu:
        </div>
      </div>

      <div className='mb-10 w-75 m-auto '>
        <div className='fw-bold text-start text-dark fs-6 mb-0 ms-1'>
          Digite o código de segurança de 6 digitos
        </div>
        <div className='d-flex flex-wrap flex-stack'>
          <input
            type='text'
            name='code_1'
            placeholder=''
            autoComplete='off'
            className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
          />
          <input
            type='text'
            name='code_2'
            placeholder=''
            autoComplete='off'
            className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
          />
          <input
            type='text'
            name='code_3'
            placeholder=''
            autoComplete='off'
            className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
          />
          <input
            type='text'
            name='code_4'
            placeholder=''
            autoComplete='off'
            className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
          />
          <input
            type='text'
            name='code_5'
            placeholder=''
            autoComplete='off'
            className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
          />
          <input
            type='text'
            name='code_6'
            placeholder=''
            autoComplete='off'
            className='form-control bg-transparent h-50px w-50px fs-2qx text-center mx-1 my-2'
          />
        </div>
      </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap flex-column justify-content-center align-items-center pb-lg-0'>
        <Link to={NEW_PASSWORD_ROUTE} className='w-75'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-primary w-100'
          >
            <span className='indicator-label'>Enviar</span>
          </button>
        </Link>

        <div className='text-gray-500 text-center mt-5 w-75 m-auto fw-semibold fs-6'>
          Não recebeu o código? <a href='#'>Reenviar</a>.
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}
